<template>
  <div class="mb-5">
    <p v-if="mode === 'edit'" class="title is-4">Edit Knowledgebase</p>
    <p v-if="mode === 'create'" class="title is-4">Create Knowledgebase</p>
    <p v-if="mode === 'create'" class="subtitle is-6">
      Create a knowledgebase article to be shown on LOW.MS. The article won't be published automatically.
    </p>
    <b-message v-if="errorAction.createKnowledgebase" type="is-danger">
      {{ errorAction.createKnowledgebase.message || errorAction.createKnowledgebase }}
    </b-message>
    <b-field grouped label="Title">
      <b-input v-model="form.title" expanded />
    </b-field>
    <b-field grouped label="Slug">
      <b-input v-model="form.seoName" expanded message="Used in the URL and other places" @input="autoSlug = false" />

      <p class="control">
        <b-button icon-left="robot" :type="autoSlug ? 'is-success' : 'is-default'" @click="onClickAutoSlug">Auto</b-button>
      </p>
    </b-field>
    <div class="columns">
      <div class="column is-one-third">
        <ScreenshotUpload class="pb-4" :product="imageSlug" title="Thumbnail" :current="form.thumbnail" type="background" @upload="onUploadImage" />
      </div>
      <div class="column is-one-third">
        <b-field grouped label="Current Thumbnail">
          <img :src="form.thumbnail" />
        </b-field>
      </div>
    </div>
    {{ form.thumbnail }}

    <div class="row">
      <b-field grouped>
        <ScreenshotUpload class="pb-4" :product="imageScreenshotSlug" title="Screenshot" type="screenshot" @upload="onUploadImage" />
      </b-field>

      <div class="columns">
        <div class="column is-one-half">
          <strong>Optimized link</strong>
          <div v-for="(screenshot, index) in form.screenshots" :key="index">
            <small style="font-size: 12px">{{ screenshot }}</small>
          </div>
        </div>
        <div class="column is-one-half">
          <strong>Original link</strong>
          <div v-for="(screenshot, index) in form.screenshots" :key="index">
            <small style="font-size: 12px">{{ screenshot.replace('.jpg', '-org.jpg') }}</small>
          </div>
        </div>
      </div>
    </div>

    <b-field grouped label="Sub-title">
      <b-input v-model="form.subTitle" expanded />
    </b-field>
    <b-field grouped label="Author">
      <b-input v-model="form.author" expanded />
    </b-field>
    <b-field grouped label="Content (markdown)">
      <div style="width: 100%">
        <markdown-editor v-model="form.content"></markdown-editor>
      </div>
    </b-field>

    <b-button class="mb-4" type="is-ghost" icon-left="circle-question" @click="showCheatsheet = !showCheatsheet">Markdown Help</b-button>
    <div v-if="showCheatsheet" class="has-background-light p-4 mb-4">
      <b-table style="white-space: pre" :data="cheatsheet.data" :columns="cheatsheet.cols" striped />
    </div>

    <b-field
      grouped
      label="Short Content"
      message="Short content is usually the first paragraph of the main content. It is used for SEO and article previews (e.g. on product pages)"
    >
      <b-input v-model="form.shortContent" type="textarea" rows="4" expanded />
    </b-field>

    <b-field grouped label="Game" message="Link this article to game server products by using the product's SEO name (leave blank for global)">
      <b-taginput
        v-model="form.gameApplicable"
        autocomplete
        :data="filteredTags"
        :allow-new="false"
        field="gameApplicable"
        expanded
        ellipsis
        icon="tags"
        placeholder="Add a game"
        aria-close-label="Delete this game"
        @typing="getFilteredTags"
      >
      </b-taginput>
    </b-field>

    <b-field grouped label="Keywords" message="SEO Keywords">
      <b-taginput
        v-model="form.keywords"
        :allow-new="true"
        field="seoName"
        expanded
        ellipsis
        icon="tags"
        placeholder="Add a keyword"
        aria-close-label="Delete this keyword"
      >
      </b-taginput>
    </b-field>

    <b-field v-if="mode === 'edit'" grouped label="Publish" message="Show this article on the LOW.MS website">
      <b-switch v-model="form.published" expanded />
    </b-field>

    <p class="title is-6 mt-4">Preview</p>
    <div class="preview has-background-light p-4 mb-4">
      <h1 class="title is-3">{{ form.title }}</h1>
      <h3 class="title is-5">{{ form.subTitle }}</h3>
      <vue-markdown :key="form.content">{{ form.content }}</vue-markdown>
    </div>

    <b-button
      class="mr-4"
      type="is-primary"
      :loading="loadingAction[mode === 'create' ? 'createKnowledgebase' : 'updateKnowledgebase']"
      :disabled="loadingAction[mode === 'create' ? 'createKnowledgebase' : 'updateKnowledgebase']"
      @click="onSubmit"
      >Submit</b-button
    >
    <b-button
      v-if="mode === 'edit'"
      class="mb-4"
      type="is-danger"
      :loading="loadingAction.deleteKnowledgebase"
      :disabled="loadingAction.deleteKnowledgebase"
      @click="onClickDelete"
      >Delete</b-button
    >
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ScreenshotUpload from '../../components/ScreenshotUpload.vue';

import slugify from 'slugify';

export default {
  name: 'WebsiteKnowledgebaseEdit',
  components: { ScreenshotUpload },
  data() {
    return {
      mode: 'create',
      form: {},
      filteredTags: this.products,
      autoSlug: true,
      showCheatsheet: false,
      cheatsheet: {
        cols: [
          {
            field: 'element',
            label: 'Element'
          },
          {
            field: 'syntax',
            label: 'Markdown Syntax'
          }
        ],
        data: [
          {
            element: 'Heading 1',
            syntax: '# Heading 1'
          },
          {
            element: 'Heading 2',
            syntax: '## Heading 2'
          },
          {
            element: 'Heading 3',
            syntax: '### Heading 3'
          },
          {
            element: 'Bold',
            syntax: '**bold text**'
          },
          {
            element: 'Italics',
            syntax: '*italicized text*'
          },
          {
            element: 'Blockquote',
            syntax: '> blockquote'
          },
          {
            element: 'Ordered List',
            syntax: '1. First item\n2. Second item\n3. Third item'
          },
          {
            element: 'Unordered List',
            syntax: '- First item\n- Second item\n- Third item'
          },
          {
            element: 'Code',
            syntax: '`code`'
          },
          {
            element: 'Divider',
            syntax: '---'
          },
          {
            element: 'Link',
            syntax: '[title](https://www.example.com)'
          },
          {
            element: 'Image',
            syntax: '![alt text](image.jpg)'
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters({
      products: 'websiteNew/products',
      knowledgebases: 'websiteNew/knowledgebases',
      loadingAction: 'websiteNew/loadingAction',
      errorAction: 'websiteNew/errorAction'
    }),
    imageSlug() {
      return { ...this.form, seoName: 'knowledgebase/' + this.form.seoName };
    },
    imageScreenshotSlug() {
      return { ...this.form, seoName: 'knowledgebase/' + this.form.seoName + (this.form.screenshots ? this.form.screenshots.length : 0) };
    },
    filteredProducts() {
      const products = this.products.map(p => p.seoName);
      products.push('all');
      products.push('billing');
      products.push('sponsorship/Partnership');
      return products;
    }
  },
  async beforeMount() {
    this.form = {};

    if (this.$route.name.includes('-edit') && this.$route.params.id) {
      this.mode = 'edit';
      this.autoSlug = false;

      await this.getKnowledgebases();
      this.form = this.knowledgebases.find(b => b._id === this.$route.params.id);
      delete this.form._id;
    } else {
      this.mode = 'create';
      this.autoSlug = true;

      this.form.author = this.$auth.user.name;
    }
  },
  mounted() {
    this.getProducts({ steam: true });
  },
  methods: {
    ...mapActions({
      getProducts: 'websiteNew/getProducts',
      getKnowledgebases: 'websiteNew/getKnowledgebases',
      updateKnowledgebase: 'websiteNew/updateKnowledgebase',
      createKnowledgebase: 'websiteNew/createKnowledgebase',
      deleteKnowledgebase: 'websiteNew/deleteKnowledgebase'
    }),
    onClickAutoSlug() {
      this.autoSlug = !this.autoSlug;
      this.form.seoName = slugify(this.form.title, { lower: true, strict: true });
    },
    getFilteredTags(text) {
      this.filteredTags = this.filteredProducts.filter(option => {
        return option.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    },
    async onClickDelete() {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this knowledgebase article?',
        onConfirm: () => this.onDelete()
      });
    },
    async onDelete() {
      await this.deleteKnowledgebase({ params: { id: this.$route.params.id } });

      this.$router.push({ name: 'website-knowledgebase' });
    },
    async onUploadImage(type, upload) {
      if (type !== 'screenshot') {
        this.form.thumbnail = upload.thumbUrl;
        await this.updateKnowledgebase({
          _id: this.form._id,
          data: {
            thumbnail: upload.thumbUrl
          }
        });
      } else {
        if (!this.form.screenshots) {
          this.form.screenshots = [];
        }

        this.form.screenshots.push(upload.url);

        await this.updateKnowledgebase({
          _id: this.form._id,
          data: {
            screenshots: this.form.screenshots
          }
        });
      }

      await new Promise(resolve => setTimeout(resolve, 3000));

      this.getKnowledgebases();
    },
    async onSubmit() {
      const body = {
        data: this.form
      };

      const updatedKnowledgebase =
        this.mode === 'create'
          ? await this.createKnowledgebase(body)
          : await this.updateKnowledgebase({ ...body, params: { id: this.$route.params.id } });

      if (updatedKnowledgebase && updatedKnowledgebase._id) {
        this.$buefy.toast.open({
          message: 'Knowledgebase post created/updated!',
          type: 'is-success'
        });
      } else {
        this.$buefy.toast.open({
          message: 'Knowledgebase post could not be created/updated',
          type: 'is-danger'
        });
      }

      if (this.mode === 'create') {
        this.form = {};
      }

      this.$router.push({ name: 'website-knowledgebase' });
    }
  },
  watch: {
    'form.title'(title) {
      if (!title || !this.autoSlug) return;
      this.form.seoName = slugify(title, { lower: true, strict: true });
    }
  }
};
</script>

<style lang="scss">
.preview {
  white-space: pre-line;
}

.v-md-toolbar {
  display: flex !important;
}
</style>
