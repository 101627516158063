<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Add/Edit Game</p>
    </header>
    <section class="modal-card-body">
      <b-field label="WHMCS Billing ID">
        <b-input v-model="form.billingId" placeholder="Enter WHMCS billing ID" type="text" />
      </b-field>
      <b-field label="Steam App ID">
        <b-input v-model="form.steamStoreId" placeholder="Enter Steam App ID" type="text" />
      </b-field>
      <b-field label="Min. Slots">
        <b-input v-model="form.minSlots" placeholder="Enter minimum slots" type="text" />
      </b-field>
      <b-field label="Pre-order">
        <b-checkbox v-model="form.isComingSoon" />
      </b-field>
      <b-field label="Featured">
        <b-checkbox v-model="form.featured" />
      </b-field>
      <b-field label="Spotlight">
        <b-checkbox v-model="form.spotlight" />
      </b-field>

      <div class="buttons">
        <button class="button" @click.prevent="onClickOverrides">Toggle Overrides</button>
        <button class="button is-info" :class="{ 'is-loading': loadingAction.createGame }" @click.prevent="onClickPreview">Preview</button>
      </div>

      <b-message v-if="errorAction.createGame" type="is-danger">
        {{ errorAction.createGame }}
      </b-message>

      <div v-if="showOverrides">
        <hr />
        <h1 class="title">Overrides</h1>
        <p class="subtitle is-6">These will override WHMCS and Steam values</p>

        <b-field label="Name">
          <b-input v-model="overrides.name" type="text" />
        </b-field>

        <b-field label="SEO Name">
          <b-input v-model="overrides.seoName" type="text" />
        </b-field>

        <b-field label="Website">
          <b-input v-model="overrides.website" type="text" />
        </b-field>

        <hr />

        <b-field label="Description (HTML)">
          <b-input v-model="overrides.description" type="textarea" />
        </b-field>

        <hr />

        <b-field label="About description (HTML)">
          <b-input v-model="overrides.aboutDescription" type="textarea" />
        </b-field>

        <hr />

        <b-field label="Short description (Text)">
          <b-input v-model="overrides.shortDescription" type="textarea" />
        </b-field>

        <hr />

        <b-field label="Header image">
          <b-input v-model="overrides.images.header" type="text" />
        </b-field>
        <div v-if="overrides.images && overrides.images.header">
          <p>Current header image</p>
          <img :src="overrides.images.header" />
        </div>
        <div v-else>
          <p>No header image set</p>
        </div>

        <b-field label="Screenshots">
          <b-taginput v-model="screenshots" />
        </b-field>
        <p>Current screenshots</p>
        <b-carousel v-if="overrides.images && overrides.images.screenshots && overrides.images.screenshots.length > 0" :indicator-inside="false">
          <b-carousel-item v-for="(image, i) in overrides.images.screenshots" :key="i">
            <span class="image">
              <img :src="image.full" />
            </span>
          </b-carousel-item>
        </b-carousel>
        <div v-else>
          <p>No screenshots set</p>
        </div>
      </div>
      <div v-if="preview.name">
        <hr />
        <h1 class="title">Preview</h1>
        <div class="card">
          <div v-if="preview.images && preview.images.header" class="card-image">
            <figure class="image">
              <img :src="preview.images.header" alt="Placeholder image" />
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <h3 class="title is-3">
                  {{ preview.name }}
                </h3>
                <p class="subtitle is-6">
                  <strong>Availability:</strong> {{ preview.stock > 0 ? 'In stock' : 'Out of stock' }} -
                  {{ preview.isComingSoon ? 'Pre-order' : 'Available now' }}
                  <br />
                  <strong>SEO Name:</strong> {{ preview.seoName }}
                </p>
              </div>
            </div>

            <div class="content">
              <h5 class="subtitle is-5">Short Description</h5>
              <p>
                {{ preview.shortDescription || 'N/A' }}
              </p>

              <h5 class="subtitle is-5">Pricing</h5>
              <p>
                <b-table :data="preview.fromPricing" default-sort="amount">
                  <template slot-scope="props">
                    <b-table-column field="period" label="Period" sortable>
                      {{ props.row.period }}
                    </b-table-column>
                    <b-table-column field="amount" label="Starting from" sortable>
                      {{ currency[props.row.currencyCode] }}{{ props.row.amount.toFixed(2) }}
                    </b-table-column>
                    <b-table-column field="perSlot" label="Per slot" sortable>
                      {{ currency[props.row.currencyCode] }}{{ props.row.perSlot.toFixed(2) }}/slot
                    </b-table-column>
                  </template>
                </b-table>
              </p>

              <div v-if="preview.images && preview.images.screenshots && preview.images.screenshots.length > 0">
                <h5 class="subtitle is-5">Screenshots</h5>
                <p>
                  <b-carousel :indicator-inside="false">
                    <b-carousel-item v-for="(image, i) in preview.images.screenshots" :key="i">
                      <span class="image">
                        <img :src="image.full" />
                      </span>
                    </b-carousel-item>
                  </b-carousel>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Close</button>
      <button class="button is-primary" @click.prevent="onClickSubmit">Submit</button>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    isEdit: {
      default: false,
      type: Boolean
    },
    billingId: {
      default: '',
      type: String
    },
    steamStoreId: {
      default: '',
      type: String
    },
    minSlots: {
      default: '',
      type: String
    },
    isComingSoon: {
      default: false,
      type: Boolean
    },
    featured: {
      default: false,
      type: Boolean
    },
    spotlight: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      form: {
        billingId: '',
        steamStoreId: '',
        minSlots: '',
        isComingSoon: false,
        featured: false,
        spotlight: false
      },
      currency: {
        EUR: '€',
        GBP: '£',
        USD: '$'
      },
      preview: {},
      showOverrides: false,
      overrides: {},
      game: {},
      screenshots: []
    };
  },
  computed: {
    ...mapGetters({
      loadingAction: 'website/loadingAction',
      errorAction: 'website/errorAction',
      games: 'website/games'
    })
  },
  mounted() {
    this.form = { ...this.$props, isEdit: undefined };

    if (this.form.billingId) {
      this.game = {
        ...this.games.find(game => game.pid === this.form.billingId)
      };
    }
  },
  methods: {
    ...mapActions({
      addWebsiteGame: 'website/gameAdd',
      updateWebsiteGame: 'website/gameUpdate',
      createWebsiteGame: 'website/createGame'
    }),
    async onClickSubmit() {
      const images = {};

      if (this.screenshots.length > 0) {
        images.screenshots = this.screenshots.map(s => ({ full: s, thumb: s }));
      }

      await this.createWebsiteGame({
        data: {
          ...this.form,
          overrides: {
            ...this.overrides
          }
        }
      });

      this.$emit('submit');

      this.$parent.close();
    },
    async onClickPreview() {
      this.preview = await this.createWebsiteGame({
        data: {
          ...this.form
        },
        preview: true
      });
    },
    onClickOverrides() {
      this.showOverrides = !this.showOverrides;

      this.overrides = {
        name: this.game.name,
        seoName: this.game.seoName,
        website: this.game.website,
        description: this.game.description,
        aboutDescription: this.game.aboutDescription,
        shortDescription: this.game.shortDescription,
        images: {
          header: this.game.images && this.game.images.header ? this.game.images.header : '',
          screenshots: this.game.images && this.game.images.screenshots ? this.game.images.screenshots : []
        }
      };

      this.screenshots = this.game.images && this.game.images.screenshots ? this.game.images.screenshots.map(s => s.full) : [];
    }
  }
};
</script>

<style>
.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
}
</style>
