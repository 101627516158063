<template>
  <div>
    <p class="title is-6 mb-2">{{ title }}<br /><small @paste="pasteFunction">Click here and use CTRL+V to paste image</small></p>
    <figure v-if="blob" class="image mb-2" :class="size">
      <img :src="blob" />
    </figure>
    <figure v-else-if="current" class="image mb-2" :class="size">
      <img :src="current" />
    </figure>
    <div class="file" :class="{ 'has-name': !!name }">
      <label class="file-label">
        <input class="file-input" type="file" name="image" @input="onSelectFile" />
        <span class="file-cta has-text-primary">
          <span class="file-icon">
            <i class="fas fa-upload"></i>
          </span>
          <span class="file-label">Choose a file…</span>
        </span>
        <span v-if="name" class="file-name has-background-white">{{ name }}</span>
      </label>
    </div>
    <div v-if="name" class="mt-3">
      <b-button type="is-primary" class="mr-3" :loading="loadingAction.uploadProductImage" @click="onUploadFile">Upload</b-button>
      <b-button :disabled="loadingAction.uploadProductImage" @click="onClearFile">Reset</b-button>
    </div>
    <div v-if="progressAction.uploadProductImage > 0 && progressAction.uploadProductImage < 100" class="mt-3">
      <b-progress :value="progressAction.uploadProductImage" show-value format="percent"></b-progress>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ScreenshotUpload',
  props: {
    type: {
      type: String,
      required: false,
      default: 'screenshot'
    },
    title: {
      type: String,
      default: 'Screenshot'
    },
    size: {
      type: String,
      default: 'is-16by9'
    },
    current: {
      type: String,
      required: false,
      default: null
    },
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      progressAction: 'websiteNew/progressAction',
      loadingAction: 'websiteNew/loadingAction'
    })
  },
  data() {
    return {
      file: null,
      blob: '',
      name: ''
    };
  },
  methods: {
    ...mapActions({
      uploadProductImage: 'websiteNew/uploadProductImage'
    }),
    pasteFunction(pasteEvent, callback) {
      if (pasteEvent.clipboardData == false) {
        if (typeof callback == 'function') {
          console.log('Undefined ');
          callback(undefined);
        }
      }
      var items = pasteEvent.clipboardData.items;
      if (items == undefined) {
        if (typeof callback == 'function') {
          callback(undefined);
          console.log('Undefined 2');
        }
      }
      for (var i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') == -1) continue;
        var blob = items[i].getAsFile();
        this.addImage(blob);
      }
    },
    addImage(blob) {
      const formData = new FormData();
      formData.append('image', blob, 'screenshot.png');
      this.name = 'screenshot.png';
      this.blob = blob;

      this.blob = window.URL.createObjectURL(blob);

      this.file = formData;
    },
    onSelectFile(e) {
      const { name, files } = e.target;

      const formData = new FormData();

      formData.append(name, files[0], files[0].name ? files[0].name : 'screenshot.png');

      this.name = files[0].name;
      this.blob = window.URL.createObjectURL(files[0]);

      this.file = formData;
    },
    async onUploadFile() {
      const randomNumber = Math.floor(Math.random() * (999999 - 100000) + 100000);
      const id = this.type === 'screenshot' ? randomNumber : this.type;
      const name = `${this.product.seoName}_${id}`;
      const extension = this.name.split('.').pop();

      const upload = await this.uploadProductImage({
        name: `${name}.${extension}`,
        file: this.file
      });

      if (upload.url) {
        this.$buefy.toast.open({
          message: 'Uploaded! Image will appear shortly...',
          type: 'is-success'
        });
        this.$emit('upload', this.type, upload);
      } else {
        this.$buefy.toast.open({
          message: 'Upload failed',
          type: 'is-danger'
        });
      }

      this.onClearFile();
    },
    onClearFile() {
      this.file = null;
      this.blob = '';
      this.name = '';
    }
  }
};
</script>
