import { getInstance } from './index';
import httpInterceptor from '../lib/HttpInterceptors';

export default () => (to, from, next) => {
  const authService = getInstance();

  const fn = async () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated && authService.isAdmin) {
      const token = await authService.auth0Client.getTokenSilently();

      httpInterceptor(token);

      return next();
    }

    return null;
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn();
    }

    return null;
  });

  return null;
};
