import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory('finance', 'finances'),
  GET_NODE_SUMMARY_REQUEST: state => {
    state.loadingAction.nodeSummary = true;
    state.nodeSummary.results = [];
    state.nodeSummary.error = null;
  },
  GET_NODE_SUMMARY_SUCCESS: (state, { body }) => {
    state.loadingAction.nodeSummary = false;
    state.nodeSummary.results = body;
    state.nodeSummary.error = null;
  },
  GET_NODE_SUMMARY_ERROR: (state, { error }) => {
    state.loadingAction.nodeSummary = false;
    state.nodeSummary.results = [];
    state.nodeSummary.error = error;
  },
  UPDATE_NODE_DATA_REQUEST: (state, { params }) => {
    if (params.nodeName) {
      state.loadingAction.updateNodeDataResponse = params.nodeName;
      state.updateNodeDataResponse = [
        ...state.updateNodeDataResponse.filter(r => r.nodeName !== params.nodeName),
        { nodeName: params.nodeName, response: null, error: null, loading: true }
      ];
    }
  },
  UPDATE_NODE_DATA_ERROR: (state, { params, error }) => {
    if (params.nodeName) {
      state.loadingAction.updateNodeDataResponse = false;
      state.updateNodeDataResponse = [
        ...state.updateNodeDataResponse.filter(r => r.nodeName !== params.nodeName),
        { nodeName: params.nodeName, response: null, error, loading: false }
      ];
    }
  },
  UPDATE_NODE_DATA_SUCCESS: (state, { params, body }) => {
    if (params.nodeName) {
      state.loadingAction.updateNodeDataResponse = false;
      state.updateNodeDataResponse = [
        ...state.updateNodeDataResponse.filter(r => r.nodeName !== params.nodeName),
        { nodeName: params.nodeName, response: body, error: null, loading: false }
      ];
    }
  }
};
