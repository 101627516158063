<template>
  <div>
    <section class="hero is-primary mb-5">
      <div class="hero-body">
        <div class="container">
          <p class="title">Website Management</p>
          <p class="subtitle">Manage LOW.MS website content</p>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="columns">
        <div class="column is-one-fifth">
          <b-menu>
            <b-menu-list label="Products">
              <b-menu-item
                icon="rotate"
                :active="$route.name === 'website-products-sync'"
                label="Sync Products"
                @click="onNavigate('website-products-sync')"
              />
              <b-menu-item
                :active="$route.name.startsWith('website-products-edit')"
                :expanded="$route.name.startsWith('website-products-edit')"
                icon="boxes-stacked"
                label="Manage Products"
                @click="onNavigate('website-products-edit')"
              >
                <b-menu-item
                  :active="$route.name === 'website-products-edit'"
                  icon="pencil"
                  label="Edit"
                  @click="onNavigate('website-products-edit')"
                ></b-menu-item>
                <b-menu-item
                  :active="$route.name === 'website-products-edit-pricing'"
                  icon="coins"
                  label="Pricing Data"
                  @click="onNavigate('website-products-edit-pricing')"
                ></b-menu-item>
                <b-menu-item
                  :active="$route.name === 'website-products-edit-steam'"
                  icon-pack="fab"
                  icon="steam"
                  label="Steam Data"
                  @click="onNavigate('website-products-edit-steam')"
                ></b-menu-item>
              </b-menu-item>
              <b-menu-item
                icon="list"
                :active="$route.name === 'website-products-options'"
                label="Product Options"
                @click="onNavigate('website-products-options')"
              />
            </b-menu-list>

            <b-menu-list label="Blog">
              <b-menu-item icon="files" label="List Articles" :active="$route.name === 'website-blogs'" @click="onNavigate('website-blogs')" />
              <b-menu-item
                icon="file-pen"
                label="Publish New"
                :active="$route.name === 'website-blogs-create'"
                @click="onNavigate('website-blogs-create')"
              />
            </b-menu-list>

            <b-menu-list label="Knowledgebase">
              <b-menu-item
                icon="files"
                label="List Articles"
                :active="$route.name === 'website-knowledgebase'"
                @click="onNavigate('website-knowledgebase')"
              />
              <b-menu-item
                icon="file-pen"
                label="Publish New"
                :active="$route.name === 'website-knowledgebase-create'"
                @click="onNavigate('website-knowledgebase-create')"
              />
            </b-menu-list>

            <b-menu-list label="Promos">
              <b-menu-item icon="ticket" label="Manage Promos" :active="$route.name === 'website-promos'" @click="onNavigate('website-promos')" />
            </b-menu-list>

            <b-menu-list label="Deployment">
              <b-menu-item icon="rocket-launch" label="Deploy Website" />
            </b-menu-list>
          </b-menu>
        </div>

        <div class="column is-four-fifths">
          <router-view :key="$route.name + $route.params.id" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'WebsiteNew',
  data() {
    return {
      subMenu: null
    };
  },
  computed: {
    ...mapGetters({})
  },
  mounted() {},
  methods: {
    ...mapActions({}),
    onNavigate(route) {
      const newRoute = { name: route };

      if (route.startsWith('website-products-edit')) {
        newRoute.query = this.$route.query;
      }

      this.$router.push(newRoute);
    }
  }
};
</script>
