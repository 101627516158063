<template>
  <div class="container">
    <section>
      <b-field label="Search">
        <b-input v-model="search" />
      </b-field>

      <p>
        <strong>Table total profit: ${{ filteredSummaryTotal.toFixed(2) }}/month (Avg. ${{ filteredAverageTotal.toFixed(2) }}/month per node)</strong>
      </p>

      <b-table :data="filteredSummary" striped default-sort="total" default-sort-direction="asc">
        <b-table-column v-slot="props" field="nodeName" label="Node Name" sortable>
          {{ props.row.nodeName }}
        </b-table-column>

        <b-table-column v-slot="props" field="count" label="No. of services" sortable numeric>
          {{ props.row.count }}
        </b-table-column>

        <b-table-column v-slot="props" field="cost" label="Cost" sortable numeric> ${{ props.row.cost.toFixed(2) }}/month </b-table-column>

        <b-table-column v-slot="props" field="total" label="Revenue" sortable numeric> ${{ props.row.total.toFixed(2) }}/month </b-table-column>

        <b-table-column v-slot="props" field="profit" label="Profit" sortable numeric>
          <strong>${{ props.row.profit.toFixed(2) }}/month</strong>
        </b-table-column>

        <b-table-column v-slot="props" label="Update" centered>
          <b-button type="is-primary" @click="() => onClickUpdateSingleNodeData(props.row.nodeName)"> Update </b-button>
        </b-table-column>
      </b-table>

      <b-button @click="onToggleUpdateNodeModal"> Update node data </b-button>

      <b-modal :active.sync="isUpdateNodeModal" scroll="keep">
        <div class="card-content">
          <div class="content">Don't spam this! It may slow down WHMCS!</div>
        </div>
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Update node data</p>
          </header>
          <section class="modal-card-body">
            <strong>Don't spam this! It may slow down WHMCS!</strong>

            <p>This will take about {{ ((this.nodes.length * 10 + 30) / 60).toFixed(0) }} minutes to complete.</p>

            <b-button @click="onClickUpdateNodeData"> Start </b-button>

            <b-table v-if="updateNodesResponse" :data="updateNodesResponse" :loading="loadingAction.updateNodeData" striped>
              <template slot-scope="props">
                <b-table-column v-slot="props" field="nodeName" label="Node Name" sortable>
                  {{ props.row.nodeName }}
                </b-table-column>
                <b-table-column label="Status" sortable>
                  <span v-if="props.row.loading">Loading...</span>
                  <span v-else>
                    <span v-if="props.row.error">Error. Check dev tools.</span>
                    <span v-else>OK</span>
                  </span>
                </b-table-column>
              </template>
            </b-table>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="onToggleUpdateNodeModal">Close</button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Finance',
  data() {
    return {
      selectedNode: '',
      isUpdateNodeModal: false,
      search: ''
    };
  },
  mounted() {
    this.getNodes();
    this.getNodeFinanceSummary();
  },
  computed: {
    ...mapGetters({
      nodes: 'node/nodes',
      loadingAction: 'node/loadingAction',
      nodeSummary: 'finance/nodeSummary',
      updateNodesResponse: 'finance/updateNodeDataResponse'
    }),
    sortedNodes() {
      const sortedNodes = [...this.nodes];
      sortedNodes.sort((a, b) => a.name.localeCompare(b.name));
      return sortedNodes;
    },
    filteredSummary() {
      const filteredResults = this.nodeSummary.results.filter(r => !this.search || r.nodeName.includes(this.search));
      return filteredResults
        .map(financeNode => {
          const updatedResult = { ...financeNode };
          const node = (this.nodes || []).find(n => n.name === `${financeNode.nodeName}.network.low.ms`);
          updatedResult.cost = node ? node.cost : 0;
          updatedResult.status = node ? node.status : 'disabled';
          updatedResult.profit = financeNode.total - updatedResult.cost;
          return updatedResult;
        })
        .filter(financeNode => financeNode.status !== 'disabled');
    },
    filteredSummaryTotal() {
      return this.filteredSummary.reduce((sum, row) => sum + row.profit, 0);
    },
    filteredAverageTotal() {
      return this.filteredSummaryTotal / this.filteredSummary.length;
    }
  },
  methods: {
    ...mapActions({
      getNodes: 'node/list',
      getNodeFinanceSummary: 'finance/getNodeSummary',
      updateNodeFinanceData: 'finance/updateNodeData'
    }),
    onToggleUpdateNodeModal() {
      this.isUpdateNodeModal = !this.isUpdateNodeModal;
    },
    onClickUpdateNodeData() {
      this.updateNodeFinanceData({});
    },
    onClickUpdateSingleNodeData(nodeClicked) {
      this.updateNodeFinanceData({ nodeName: nodeClicked });
    }
  }
};
</script>
