<template>
  <div class="mb-5">
    <p v-if="mode === 'edit'" class="title is-4">Edit Blog</p>
    <p v-if="mode === 'create'" class="title is-4">Create Blog</p>
    <p v-if="mode === 'create'" class="subtitle is-6">Create a blog article to be shown on LOW.MS. The article won't be published automatically.</p>
    <b-message v-if="errorAction.createBlog" type="is-danger">
      {{ errorAction.createBlog.message || errorAction.createBlog }}
    </b-message>
    <b-field grouped label="Title">
      <b-input v-model="form.title" expanded />
    </b-field>
    <b-field grouped label="Slug">
      <b-input v-model="form.seoName" expanded message="Used in the URL and other places" @input="autoSlug = false" />

      <p class="control">
        <b-button icon-left="robot" :type="autoSlug ? 'is-success' : 'is-default'" @click="onClickAutoSlug">Auto</b-button>
      </p>
    </b-field>
    <div class="columns">
      <div class="column is-one-third">
        <ScreenshotUpload class="pb-4" :product="imageSlug" title="Thumbnail" :current="form.thumbnail" type="background" @upload="onUploadImage" />
      </div>
      <div class="column is-one-third">
        <b-field grouped label="Current Thumbnail">
          <img :src="form.thumbnail" />
        </b-field>
      </div>
    </div>
    {{ form.thumbnail }}

    <div class="row">
      <b-field grouped>
        <ScreenshotUpload class="pb-4" :product="imageScreenshotSlug" title="Screenshot" type="screenshot" @upload="onUploadImage" />
      </b-field>

      <div class="columns">
        <div class="column is-one-half">
          <strong>Optimized link</strong>
          <div v-for="(screenshot, index) in form.screenshots" :key="index">
            <small style="font-size: 12px">{{ screenshot }}</small>
          </div>
        </div>
        <div class="column is-one-half">
          <strong>Original link</strong>
          <div v-for="(screenshot, index) in form.screenshots" :key="index">
            <small style="font-size: 12px">{{ screenshot.replace('.jpg', '-org.jpg') }}</small>
          </div>
        </div>
      </div>
    </div>
    <b-field grouped label="Sub-title">
      <b-input v-model="form.subTitle" expanded />
    </b-field>
    <b-field grouped label="Author">
      <b-input v-model="form.author" expanded />
    </b-field>
    <b-field grouped label="Content (markdown)">
      <div style="width: 100%">
        <markdown-editor v-model="form.content"></markdown-editor>
      </div>
    </b-field>

    <b-button class="mb-4" type="is-ghost" icon-left="circle-question" @click="showCheatsheet = !showCheatsheet">Markdown Help</b-button>
    <div v-if="showCheatsheet" class="has-background-light p-4 mb-4">
      <b-table style="white-space: pre" :data="cheatsheet.data" :columns="cheatsheet.cols" striped />
    </div>

    <b-field
      grouped
      label="Short Content"
      message="Short content is usually the first paragraph of the main content. It is used for SEO and article previews (e.g. on product pages)"
    >
      <b-input v-model="form.shortContent" type="textarea" rows="4" expanded />
    </b-field>

    <b-field grouped label="Keywords" message="Link this article to game server products by using the product's SEO name">
      <b-taginput v-model="form.keywords" expanded ellipsis icon="tags" placeholder="Add a keyword" aria-close-label="Delete this keyword">
      </b-taginput>
    </b-field>

    <b-field v-if="mode === 'edit'" grouped label="Publish" message="Show this article on the LOW.MS website">
      <b-switch v-model="form.published" expanded />
    </b-field>

    <p class="title is-6 mt-4">Preview</p>
    <div class="preview has-background-light p-4 mb-4">
      <h1 class="title is-3">{{ form.title }}</h1>
      <h3 class="title is-5">{{ form.subTitle }}</h3>
      <vue-markdown :key="form.content">{{ form.content }}</vue-markdown>
    </div>

    <b-button
      class="mr-4"
      type="is-primary"
      :loading="loadingAction[mode === 'create' ? 'createBlog' : 'updateBlog']"
      :disabled="loadingAction[mode === 'create' ? 'createBlog' : 'updateBlog']"
      @click="onSubmit"
      >Submit</b-button
    >
    <b-button
      v-if="mode === 'edit'"
      class="mb-4"
      type="is-danger"
      :loading="loadingAction.deleteBlog"
      :disabled="loadingAction.deleteBlog"
      @click="onClickDelete"
      >Delete</b-button
    >
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ScreenshotUpload from '../../components/ScreenshotUpload.vue';

import slugify from 'slugify';

export default {
  name: 'WebsiteBlogEdit',
  components: { ScreenshotUpload },
  data() {
    return {
      mode: 'create',
      form: {},
      autoSlug: true,
      showCheatsheet: false,
      cheatsheet: {
        cols: [
          {
            field: 'element',
            label: 'Element'
          },
          {
            field: 'syntax',
            label: 'Markdown Syntax'
          }
        ],
        data: [
          {
            element: 'Heading 1',
            syntax: '# Heading 1'
          },
          {
            element: 'Heading 2',
            syntax: '## Heading 2'
          },
          {
            element: 'Heading 3',
            syntax: '### Heading 3'
          },
          {
            element: 'Bold',
            syntax: '**bold text**'
          },
          {
            element: 'Italics',
            syntax: '*italicized text*'
          },
          {
            element: 'Blockquote',
            syntax: '> blockquote'
          },
          {
            element: 'Ordered List',
            syntax: '1. First item\n2. Second item\n3. Third item'
          },
          {
            element: 'Unordered List',
            syntax: '- First item\n- Second item\n- Third item'
          },
          {
            element: 'Code',
            syntax: '`code`'
          },
          {
            element: 'Divider',
            syntax: '---'
          },
          {
            element: 'Link',
            syntax: '[title](https://www.example.com)'
          },
          {
            element: 'Image',
            syntax: '![alt text](image.jpg)'
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters({
      blogs: 'websiteNew/blogs',
      loadingAction: 'websiteNew/loadingAction',
      errorAction: 'websiteNew/errorAction'
    }),
    imageSlug() {
      return { ...this.form, seoName: 'knowledgebase/' + this.form.seoName };
    },
    imageScreenshotSlug() {
      return { ...this.form, seoName: 'knowledgebase/' + this.form.seoName + (this.form.screenshots ? this.form.screenshots.length : 0) };
    }
  },
  async beforeMount() {
    this.form = {};

    if (this.$route.name.includes('-edit') && this.$route.params.id) {
      this.mode = 'edit';
      this.autoSlug = false;

      await this.getBlogs();
      this.form = this.blogs.find(b => b._id === this.$route.params.id);
      delete this.form._id;
    } else {
      this.mode = 'create';
      this.autoSlug = true;

      this.form.author = this.$auth.user.name;
    }
  },
  methods: {
    ...mapActions({
      getBlogs: 'websiteNew/getBlogs',
      updateBlog: 'websiteNew/updateBlog',
      createBlog: 'websiteNew/createBlog',
      deleteBlog: 'websiteNew/deleteBlog'
    }),
    onClickAutoSlug() {
      this.autoSlug = !this.autoSlug;
      this.form.seoName = slugify(this.form.title, { lower: true, strict: true });
    },
    async onClickDelete() {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this blog article?',
        onConfirm: () => this.onDelete()
      });
    },
    async onDelete() {
      await this.deleteBlog({ params: { id: this.$route.params.id } });

      this.$router.push({ name: 'website-blogs' });
    },
    async onUploadImage(type, upload) {
      if (type !== 'screenshot') {
        this.form.thumbnail = upload.thumbUrl;
        await this.updateBlog({
          _id: this.form._id,
          data: {
            thumbnail: upload.thumbUrl
          }
        });
      } else {
        if (!this.form.screenshots) {
          this.form.screenshots = [];
        }

        this.form.screenshots.push(upload.url);

        await this.updateBlog({
          _id: this.form._id,
          data: {
            screenshots: this.form.screenshots
          }
        });
      }

      await new Promise(resolve => setTimeout(resolve, 3000));

      this.getKnowledgebases();
    },
    async onSubmit() {
      const body = {
        data: this.form
      };

      const updatedBlog =
        this.mode === 'create' ? await this.createBlog(body) : await this.updateBlog({ ...body, params: { id: this.$route.params.id } });

      if (updatedBlog && updatedBlog._id) {
        this.$buefy.toast.open({
          message: 'Blog post created/updated!',
          type: 'is-success'
        });
      } else {
        this.$buefy.toast.open({
          message: 'Blog post could not be created/updated',
          type: 'is-danger'
        });
      }

      if (this.mode === 'create') {
        this.form = {};
      }

      this.$router.push({ name: 'website-blogs' });
    }
  },
  watch: {
    'form.title'(title) {
      if (!title || !this.autoSlug) return;
      this.form.seoName = slugify(title, { lower: true, strict: true });
    }
  }
};
</script>
<style lang="scss" scoped>
.preview {
  white-space: pre-line;
}
</style>
