import actions from './actions';
import mutations from './mutations';
import crudActions from '../../lib/crudActions';

const store = {
  namespaced: true,
  state: {
    loading: false,
    error: '',
    games: [],
    totalGames: 0,
    deployStatus: {},
    loadingAction: {
      ...crudActions,
      getGames: false,
      createGame: false,
      deploy: false,
      deployStatus: false
    },
    errorAction: {
      ...crudActions,
      getGames: null,
      createGame: null,
      deploy: null,
      deployStatus: null
    }
  },
  getters: {
    loading: state => state.loading,
    error: state => state.error,
    games: state => state.games,
    totalGames: state => state.totalGames,
    deployStatus: state => state.deployStatus,
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
