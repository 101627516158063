<template>
  <div>
    <div class="block">
      <h4 class="title is-4">View Prices</h4>
      <b-tabs v-model="activeTab">
        <b-tab-item label="View">
          <b-table :data="products">
            <b-table-column v-slot="props" field="name" label="Name">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column v-slot="props" field="slotPricing" label="LOW.MS"> £{{ pricePerSlot(props.row.slotPricing) }} </b-table-column>
            <b-table-column v-slot="props" field="slotPricing" label="Nitrado">
              <span v-html="enemyPricePerSlot(props.row.name, 'Nitrado', props.row.slotPricing)"></span>
            </b-table-column>
            <b-table-column v-slot="props" field="slotPricing" label="G-Portal">
              <span v-html="enemyPricePerSlot(props.row.name, 'GPortal', props.row.slotPricing)"></span>
            </b-table-column>
          </b-table>
        </b-tab-item>
        <b-tab-item label="Overrides">
          <b-table :data="prices.data">
            <b-table-column v-slot="props" field="company" label="Company">
              {{ props.row.company }}
            </b-table-column>
            <b-table-column v-slot="props" field="title" label="Game"> {{ props.row.title }} </b-table-column>
            <b-table-column v-slot="props" field="minPrice" label="minPrice"> {{ props.row.minPrice }} </b-table-column>
            <b-table-column v-slot="props" field="minSlots" label="minSlots"> {{ props.row.minSlots }} </b-table-column>
            <b-table-column v-slot="props" field="perSlot" label="perSlot"> {{ props.row.perSlot }} </b-table-column>
            <b-table-column v-slot="props" field="titleOverride" label="Override">
              {{ props.row.titleOverride }}
            </b-table-column>
            <b-table-column v-slot="props" width="230" label="Actions">
              <b-button type="is-info" size="is-small" @click.prevent="() => onClickOverride(props.row)">Override</b-button>
            </b-table-column>
          </b-table>
        </b-tab-item>
        <b-tab-item label="Missing Games">
          <b-table :data="missingGames">
            <b-table-column v-slot="props" field="company" label="Company">
              {{ props.row.company }}
            </b-table-column>
            <b-table-column v-slot="props" field="title" label="Game"> {{ props.row.title }} </b-table-column>
            <b-table-column v-slot="props" field="minPrice" label="minPrice"> {{ props.row.minPrice }} </b-table-column>
            <b-table-column v-slot="props" field="minSlots" label="minSlots"> {{ props.row.minSlots }} </b-table-column>
            <b-table-column v-slot="props" field="perSlot" label="perSlot"> {{ props.row.perSlot }} </b-table-column>
            <b-table-column v-slot="props" field="titleOverride" label="Override">
              {{ props.row.titleOverride }}
            </b-table-column>
            <b-table-column v-slot="props" width="230" label="Actions">
              <b-button type="is-info" size="is-small" @click.prevent="() => onClickOverride(props.row)">Override</b-button>
            </b-table-column>
          </b-table>
        </b-tab-item>
        <b-tab-item label="Scrapers">
          <b-button @click.prevent="() => onClickCreate()">Create</b-button>
          <b-modal :active.sync="isEditModal" has-modal-card scroll="keep">
            <ScraperForm v-bind="editProps" @submit="refresh" />
          </b-modal>
          <b-modal :active.sync="executingScraper" has-modal-card title="Scraper logs">
            <div class="modal-card">
              <header class="modal-card-head">
                <p class="modal-card-title">Executing Scraper</p>
              </header>

              <section class="modal-card-body">
                <div v-for="msg in messages" :key="msg">{{ msg }}</div>
              </section>

              <footer class="modal-card-foot">
                <button class="button" type="button" @click="$parent.close()">Close</button>
              </footer>
            </div>
          </b-modal>
          <b-table :data="scrapers.data">
            <b-table-column v-slot="props" field="company" label="Company">
              {{ props.row.company }}
            </b-table-column>
            <b-table-column v-slot="props" field="urls" label="URLs"> {{ props.row.urls }} </b-table-column>
            <b-table-column v-slot="props" field="type" label="Type">
              {{ props.row.type }}
            </b-table-column>
            <b-table-column v-slot="props" width="230" label="Actions">
              <b-button type="is-success" size="is-small" @click.prevent="() => onClickExecute(props.row)">Execute</b-button>
              <b-button type="is-info" size="is-small" @click.prevent="() => onClickModify(props.row)">Modify</b-button>
              <b-button type="is-danger" size="is-small" @click.prevent="() => onClickDelete(props.row)">Delete</b-button>
            </b-table-column>
          </b-table>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ScraperForm from './WebsitePricesScraperForm';
import io from 'socket.io-client';

export default {
  name: 'WebsitePrices',
  components: {
    ScraperForm
  },
  data() {
    return {
      activeTab: 0,
      isEditModal: false,
      executingScraper: false,
      socket: null,
      messages: [],
      editProps: {},
      prices: [],
      newFaq: {
        question: '',
        answer: ''
      },
      selectedProductId: null,
      productChanges: {
        overrides: {}
      },
      currencies: {
        GBP: '£',
        USD: '$',
        EUR: '€'
      }
    };
  },
  computed: {
    ...mapGetters({
      products: 'websiteNew/products',
      scrapers: 'websiteNew/scrapers',
      loadingAction: 'websiteNew/loadingAction'
    }),
    missingGames() {
      let missingGames = [];
      this.prices.data.forEach(price => {
        const matchingProduct = this.products.find(product => product.name === price.title.replace(/\([^()]*\)/, '').trim());
        if (!matchingProduct) {
          missingGames.push(price);
        }
      });
      return missingGames;
    },
    groupedProducts() {
      return this.products.reduce((acc, product) => {
        const group = product.gid;
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(product);
        return acc;
      }, {});
    },
    selectedProduct() {
      const product = this.products.find(product => product._id === this.selectedProductId);
      if (!product.images) {
        product.images = {
          header: '',
          screenshots: []
        };
      }
      return product || {};
    },
    slotPricing() {
      const pricing = this.selectedProduct.slotPricing.reduce((acc, item) => {
        if (!acc.some(p => p.slots === item.slots)) {
          acc.push({
            slots: item.slots
          });
        }
        const slotItem = acc.find(p => p.slots === item.slots);
        slotItem[`${item.period}Amount${item.currencyCode}`] = item.amount;
        slotItem[`${item.period}PerSlot${item.currencyCode}`] = item.perSlot;
        return acc;
      }, []);
      pricing.sort((a, b) => a.slots - b.slots);
      return pricing;
    },
    fromPricing() {
      const pricing = this.selectedProduct.fromPricing.reduce((acc, item) => {
        if (!acc.some(p => p.slots === item.slots)) {
          acc.push({
            slots: item.slots
          });
        }
        const slotItem = acc.find(p => p.slots === item.slots);
        slotItem[`${item.period}Amount${item.currencyCode}`] = item.amount;
        slotItem[`${item.period}PerSlot${item.currencyCode}`] = item.perSlot;
        return acc;
      }, []);
      pricing.sort((a, b) => a.slots - b.slots);
      return pricing;
    }
  },
  async mounted() {
    this.getProducts({ steam: true });
    this.getScrapers();
    this.prices = await this.getPrices();

    this.prices.data.sort((a, b) => a.title.localeCompare(b.title));

    if (this.$route.query.productId) {
      this.selectedProductId = this.$route.query.productId;
      this.productChanges.overrides = { ...(this.selectedProduct.overrides || {}) };
    }
  },
  methods: {
    ...mapActions({
      getProducts: 'websiteNew/getProducts',
      getScrapers: 'websiteNew/getScrapers',
      deleteScraper: 'websiteNew/deleteScraper',
      executeScraper: 'websiteNew/executeScraper',
      getPrices: 'websiteNew/getPrices'
    }),
    async refresh() {
      this.getScrapers();
    },
    async onClickModify(item) {
      this.editProps = {
        item,
        isEdit: true
      };
      this.isEditModal = true;
    },
    async onClickExecute(item) {
      this.executingScraper = true;
      this.socket = io(`http://localhost:3001`);

      await this.socket.on(`scraper${item.company}Message`, data => {
        this.messages = [...this.messages, data];
      });
      await this.startScraper(item);
    },
    async startScraper(item) {
      const data = await this.executeScraper(item);
      console.log(data);
    },
    async onClickCreate() {
      this.editProps = {
        isEdit: false
      };
      this.isEditModal = true;
    },
    async onClickDelete(item) {
      await this.deleteScraper(item);
      this.refresh();
    },
    enemyPricePerSlot(name, enemy, price) {
      const data = this.prices.data.filter(
        item =>
          item.title
            .replace(/\([^()]*\)/, '')
            .trim()
            .toLowerCase() === name.toLowerCase() && item.company === enemy
      )[0];

      if (data) {
        const percent = (((data.perSlot - this.pricePerSlot(price)) / this.pricePerSlot(price)) * 100).toFixed(2);
        if (percent === 1) {
          return '£' + data.perSlot + ` <strong>(${percent}%)</strong>`;
        } else if (percent > 5) {
          return '£' + data.perSlot + ` <strong style="color:green">(${percent}%)</strong>`;
        } else if (percent < -5) {
          return '£' + data.perSlot + ` <strong style="color:red">(${percent}%)</strong>`;
        }
      }
    },
    pricePerSlot(data) {
      const filteredData = data.filter(item => item.currencyCode === 'GBP' && item.period === 'monthly');

      if (filteredData.length > 0) {
        const minSlots = Math.min(...filteredData.map(item => item.slots));
        const result = filteredData.find(item => item.slots === minSlots);

        if (result) {
          return (result.perSlot.toFixed(2) * 1.2).toFixed(2);
        } else {
          console.log('No matching data found.');
        }
      } else {
        console.log('No matching data found.');
      }
      return 'N/A';
    }
  }
};
</script>
