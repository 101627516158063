<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" href="/">
        <img src="../assets/logo.png" width="154" height="28" />
      </a>

      <a role="button" class="navbar-burger burger" data-target="navbarBasicExample" />
    </div>

    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-start">
        <router-link class="navbar-item" to="/"> Home </router-link>
        <router-link v-if="$auth.isAdmin" class="navbar-item" to="/nodes"> Nodes </router-link>
        <router-link v-if="$auth.isAdmin" class="navbar-item" to="/finance"> Finance </router-link>
        <router-link class="navbar-item" to="/backups"> Backups </router-link>
        <router-link class="navbar-item" to="/mods"> Mods </router-link>
        <router-link v-if="$auth.isAdmin" class="navbar-item" to="/websites"> Websites </router-link>
        <router-link v-if="$auth.isAdmin" class="navbar-item" to="/website-new"> Website (NEW) </router-link>
        <router-link class="navbar-item" to="/account"> Account </router-link>
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <a v-if="!isAuthenticated" class="button is-primary" @click.prevent="login"> Log in </a>
            <a v-else class="button is-light" @click.prevent="logout"> Log out </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'Navbar',
  computed: {
    isAuthenticated() {
      return this.$auth.user && this.$auth.user.name;
    }
  },
  methods: {
    login() {
      this.$auth.login();
    },
    logout() {
      this.$auth.logout();
    }
  }
};
</script>
