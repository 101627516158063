<template>
  <div class="container">
    <section>
      <b-modal :active.sync="isEditModal" has-modal-card scroll="keep">
        <NodeForm v-bind="editProps" @submit="refresh" />
      </b-modal>

      <b-tabs v-model="activeTab" @input="onChangeTab">
        <b-tab-item label="List" value="list">
          <div class="is-flex is-justify-content-end mt-2">
            <b-button type="is-primary" @click.prevent="onClickCreate"> Create </b-button>
          </div>

          <b-field label="Search">
            <b-input v-model="search" @input="refresh" />
          </b-field>

          <b-table
            :data="filteredNodes"
            :loading="loadingAction.list"
            paginated
            backend-pagination
            :total="filteredNodes.length"
            :per-page="perPage"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            @page-change="onPageChange"
            @sort="onSort"
          >
            <b-table-column v-slot="props" field="name" label="Name" sortable>
              {{ props.row.name }}
            </b-table-column>
            <b-table-column v-slot="props" field="status" label="Status" sortable>
              {{ props.row.status }}
            </b-table-column>
            <b-table-column v-slot="props" field="primaryIp" label="Primary Ip" sortable>
              {{ props.row.primaryIp }}
            </b-table-column>
            <b-table-column v-if="$auth.isAdmin" v-slot="props" field="cost" label="Cost" sortable>
              <span v-if="props.row.cost">${{ props.row.cost.toFixed(2) }}</span>
            </b-table-column>
            <b-table-column v-slot="props" field="isCustomer" label="Cust?" sortable>
              {{ props.row.isCustomer ? 'Yes' : 'No' }}
            </b-table-column>
            <b-table-column v-slot="props" field="renewalDate" label="Renewal" sortable>
              {{ props.row.renewalDate ? props.row.renewalDate.slice(0, 10) : 'N/A' }}
            </b-table-column>
            <b-table-column v-if="$auth.isAdmin" v-slot="props" label="Actions" sortable>
              <b-button v-if="props.row.status === 'pending-setup'" size="is-small" @click.prevent="() => onClickProvisionNode(props.row.id)">
                Provision
              </b-button>
              <b-button
                size="is-small"
                :loading="loadingAction.checkDns === props.row.id"
                :disabled="loadingAction.checkDns === props.row.id"
                @click.prevent="() => onClickCheckDns(props.row.id)"
              >
                DNS Check&nbsp;
                <span v-if="checkDnsResponse[props.row.id] && checkDnsResponse[props.row.id].some(record => props.row.name.includes(record.name))">
                  <b-icon pack="fas" icon="check" size="is-small" type="is-success" />
                </span>
                <span v-else-if="checkDnsError[props.row.id]">
                  <b-icon pack="fas" icon="times" size="is-small" type="is-danger" />
                </span>
              </b-button>
              <b-button size="is-small" type="is-info" @click.prevent="() => onClickEdit(props.row.id)"> Edit </b-button>
              <b-button size="is-small" type="is-danger" @click.prevent="() => onClickRemove(props.row.id)">
                <span v-if="!deleting[props.row.id]">Delete</span>
                <span v-else>Deleting...&nbsp;<b-icon pack="fas" icon="circle-notch" size="is-small" /> </span>
              </b-button>
            </b-table-column>
          </b-table>
        </b-tab-item>

        <b-tab-item label="Monitor" value="monitor">
          <div class="is-flex is-justify-content-end mt-2">
            <b-button v-if="monitor.isMonitoring" type="is-primary" @click.prevent="stopMonitoring"> Stop Monitoring </b-button>
            <b-button v-else type="is-primary" @click.prevent="startMonitoring"> Start Monitoring </b-button>
          </div>
          <b-table
            :data="filteredMonitorNodes.nodes"
            :loading="loadingAction.list"
            paginated
            backend-pagination
            :total="filteredMonitorNodes.nodes.length"
            :per-page="perPage"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            @page-change="onPageChange"
            @sort="onSort"
          >
            <b-table-column v-slot="props" field="name" label="Name" sortable>
              {{ props.row.name }}
            </b-table-column>

            <!-- Node Summary Fields -->
            <b-table-column v-slot="props" field="hostname" label="Hostname" sortable>
              <span v-if="props.row.nodeSummary">{{ props.row.nodeSummary.hostname }}</span>
              <span v-else>-</span>
            </b-table-column>
            <b-table-column v-slot="props" field="cpu" label="CPU" sortable>
              <span v-if="props.row.nodeSummary">{{ props.row.nodeSummary.cpu }}</span>
              <span v-else>-</span>
            </b-table-column>
            <b-table-column v-slot="props" field="ram" label="RAM" sortable>
              <span v-if="props.row.nodeSummary"
                >{{
                  Math.ceil(props.row.nodeSummary.totalMemory / 1024 / 1024 / 1024) - Math.ceil(props.row.nodeSummary.freeMemory / 1024 / 1024 / 1024)
                }}
                / {{ Math.ceil(props.row.nodeSummary.totalMemory / 1024 / 1024 / 1024) }} GB</span
              >
              <span v-else>-</span>
            </b-table-column>

            <b-table-column v-slot="props" field="status" label="Status" sortable>
              {{ props.row.monitorEnabled ? 'Enabled' : '-' }}
            </b-table-column>
            <b-table-column v-if="$auth.isAdmin" v-slot="props" label="Actions">
              <b-button
                class="mr-3"
                :type="props.row.monitorEnabled ? 'is-danger' : 'is-success'"
                size="is-small"
                :disabled="!props.row.key"
                @click.prevent="() => onClickToggleMonitorEnable(props.row.id)"
                >{{ props.row.monitorEnabled ? 'Disable' : 'Enable' }}</b-button
              >
              <b-button v-if="!props.row.key" size="is-small" @click.prevent="() => onClickMonitorSetup(props.row.id)">Setup</b-button>
              <b-button v-if="props.row.key" class="mr-3" size="is-small" @click.prevent="() => onClickMonitorConfig(props.row.id)">Config</b-button>
              <b-button v-if="props.row.key" size="is-small" @click.prevent="() => onClickMonitorCrossArk(props.row.id)">Cross ARK</b-button>
            </b-table-column>
          </b-table>
        </b-tab-item>
      </b-tabs>
    </section>
    <b-modal v-model="modals.crossArk.show" :destroy-on-hide="false">
      <div class="card">
        <div class="card-content">
          <b-button size="is-small" @click="$set(modals.crossArk, 'showNew', !modals.crossArk.showNew)">Toggle Form</b-button>
          <div v-if="modals.crossArk.showNew" class="mb-4">
            <b-field label="Name">
              <b-input v-model="forms.crossArk.name"></b-input>
            </b-field>
            <b-field label="Cluster ID (Must be unique accross LOW.MS)">
              <b-input v-model="forms.crossArk.clusterId"></b-input>
            </b-field>
            <b-field label="TCAdmin Service ID">
              <b-input v-model="forms.crossArk.serviceId"></b-input>
            </b-field>
            <b-field label="Absolute Windows Path">
              <b-input v-model="forms.crossArk.path"></b-input>
            </b-field>
            <b-field label="S3 Customer Bucket">
              <b-input v-model="forms.crossArk.bucket"></b-input>
            </b-field>
            <b-button type="is-primary" @click="onClickAddCrossArk(modals.crossArk.nodeId)">Create New</b-button>
          </div>
          <b-table v-if="modals.crossArk.tableData.length > 0" ref="crossArkTable" :data="modals.crossArk.tableData">
            <b-table-column v-slot="props" field="name" label="Name">{{ props.row.name }}</b-table-column>
            <b-table-column v-slot="props" field="serviceId" label="TCA ID">{{ props.row.serviceId }}</b-table-column>
            <b-table-column v-slot="props" field="clusterId" label="Cluster ID">{{ props.row.clusterId }}</b-table-column>
            <b-table-column v-slot="props" field="bucket" label="Bucket">{{ props.row.bucket }}</b-table-column>
            <b-table-column v-slot="props" field="path" label="Path">{{ props.row.path }}</b-table-column>
            <b-table-column v-slot="props" field="actions" label="Actions">
              <b-button class="mr-3" size="is-small" @click.prevent="onClickWatchCrossArk(modals.crossArk.nodeId, props.row)">Watch</b-button>
              <b-button size="is-small" type="is-danger" @click.prevent="onClickRemoveCrossArk(modals.crossArk.nodeId, props.row)">Remove</b-button>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import NodeForm from './NodeForm';

const initialCrossArkModal = () => ({ show: false, tableData: [], nodeId: null, showNew: false });

const initialCrossArkForm = () => ({
  name: 'Woodlands - ASH12',
  clusterId: '123',
  serviceId: '123',
  path: 'C:/TCAFiles/Users/jonathan/cluster',
  bucket: 'lowms-customers'
});

export default {
  name: 'Node',
  data() {
    return {
      modals: { crossArk: initialCrossArkModal() },
      forms: {
        crossArk: initialCrossArkForm()
      },
      perPage: 25,
      page: 1,
      sortField: 'name',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      search: '',
      isDnsCheckModal: false,
      isEditModal: false,
      editProps: {},
      deleting: {},
      activeTab: 'list',
      monitor: {
        pingerInterval: null,
        monitorInterval: null
      }
    };
  },
  mounted() {
    this.refresh();
  },
  beforeDestroy() {
    this.disconnectMonitor();
  },
  computed: {
    ...mapGetters({
      nodes: 'node/nodes',
      loading: 'node/loading',
      error: 'node/error',
      loadingAction: 'node/loadingAction',
      totalNodes: 'node/total',
      checkDnsResponse: 'node/checkDnsResponse',
      checkDnsError: 'node/checkDnsError',
      monitoring: 'node/monitoring'
    }),
    actions() {
      return [
        {
          label: 'Edit',
          route: 'nodeEdit',
          class: 'btn-primary',
          params: { id: '_id' },
          conditional: 'item.active'
        }
      ];
    },
    filteredNodes() {
      return this.nodes.filter(node => node.status != 'disabled');
    },
    filteredMonitorNodes() {
      return { nodes: this.monitoring.nodes.filter(node => node.status != 'disabled') };
    }
  },
  methods: {
    ...mapActions({
      getNodes: 'node/list',
      checkDns: 'node/checkDns',
      provisionNode: 'node/provision',
      removeNode: 'node/remove',
      updateNode: 'node/update',
      monitorSetup: 'node/monitorSetup',
      monitorConfig: 'node/monitorConfig'
    }),
    ...mapMutations({
      handleMonitoringResponse: 'node/HANDLE_MONITORING_RESPONSE',
      startMonitoringStore: 'node/START_MONITORING',
      stopMonitoringStore: 'node/STOP_MONITORING',
      refreshMonitoringStore: 'node/REFRESH_MONITORING'
    }),
    onClickNew() {
      this.$router.push({ path: '/nodes/create' });
    },
    async refresh() {
      const params = {
        $sort: `${this.sortField}:${this.sortOrder}`
      };

      if (this.search) {
        params.$search = { name: this.search };
      }

      await this.getNodes({
        data: {
          params
        }
      });

      this.refreshMonitoringStore();
    },
    onPageChange(page) {
      this.page = page;
      this.refresh();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.refresh();
    },
    onClickCheckDns(id) {
      this.checkDns({ id });
    },
    async onClickProvisionNode(id) {
      await this.provisionNode({ id });
      await this.refresh();
    },
    onClickCreate() {
      this.editProps = { isEdit: false };
      this.isEditModal = true;
    },
    onClickEdit(id) {
      const node = this.nodes.find(node => node.id === id);
      if (!node.ipmi) {
        node.ipmi = {
          ipAddress: '',
          username: '',
          password: ''
        };
      }
      this.editProps = {
        ...node,
        isEdit: true
      };
      this.isEditModal = true;
    },
    async onClickRemove(id) {
      this.deleting[id] = true;
      await this.removeNode({ id });
      await this.refresh();
    },
    onChangeTab() {
      if (this.activeTab === 'monitor') {
        this.connectMonitor();
      } else {
        this.disconnectMonitor();
      }
    },
    async connectMonitor() {
      const token = await this.$auth.getTokenSilently();
      this.$connect(`${process.env.VUE_APP_MONITOR_URL}?key=${token}`);

      this.$socket.onopen = data => {
        console.log('open', data);
      };

      this.$socket.onclose = data => {
        console.log('close', data);
        this.stopMonitoring();
      };

      this.$socket.onerror = data => {
        console.log('error', data);
      };

      this.websocketPinger();
    },
    async disconnectMonitor() {
      this.stopMonitoring();

      if (this.monitor.pingerInterval) clearInterval(this.monitor.pingerInterval);
      this.$disconnect();
    },
    async startMonitoring() {
      this.monitor.isMonitoring = true;
      this.startMonitoringStore();

      this.$socket.sendObj({
        action: 'nodes',
        subAction: 'summary'
      });

      this.$socket.onmessage = rawMessage => {
        const message = JSON.parse(rawMessage.data);
        console.log(message);
        if (!message.response || !message.requestBy) return;

        if (message.subAction === 'summaryResponse') {
          this.handleMonitoringResponse(message);
        }
      };

      // Get node summary every X seconds
      this.monitor.monitorInterval = setInterval(() => {
        this.$socket.sendObj({
          action: 'nodes',
          subAction: 'summary'
        });
      }, 15000);
    },
    async stopMonitoring() {
      this.stopMonitoringStore();
      clearInterval(this.monitor.monitorInterval);
      this.monitor.isMonitoring = false;
    },
    websocketPinger() {
      if (this.monitor.pingerInterval) clearInterval(this.monitor.pingerInterval);

      this.monitor.pingerInterval = setInterval(() => {
        this.$socket.sendObj({ action: 'ping' });
      }, 180000);
    },
    async onClickMonitorCrossArk(nodeId) {
      const node = this.monitoring.nodes.find(node => node.id === nodeId);

      this.modals.crossArk = {
        tableData: node.crossArk,
        show: true,
        nodeId
      };
    },
    async onClickAddCrossArk(nodeId) {
      const node = this.monitoring.nodes.find(node => node.id === nodeId);

      await this.updateNode({
        id: nodeId,
        data: {
          crossArk: [...(node.crossArk || []), this.forms.crossArk]
        }
      });

      this.$socket.sendObj({
        action: 'nodes',
        subAction: 'crossArk',
        data: {
          ...this.forms.crossArk,
          action: 'startWatching',
          nodeId: nodeId
        }
      });

      this.forms.crossArk = initialCrossArkForm();
      this.modals.crossArk = initialCrossArkModal();

      await this.refresh();
    },
    onClickWatchCrossArk(nodeId, crossArk) {
      this.$socket.sendObj({
        action: 'nodes',
        subAction: 'crossArk',
        data: {
          ...crossArk,
          action: 'startWatching',
          nodeId: nodeId
        }
      });
    },

    async onClickRemoveCrossArk(nodeId, crossArk) {
      const node = this.monitoring.nodes.find(node => node.id === nodeId);

      await this.updateNode({
        id: nodeId,
        data: {
          crossArk: (node.crossArk || []).filter(c => c.serviceId !== crossArk.serviceId)
        }
      });

      await this.refresh();

      this.$socket.sendObj({
        action: 'nodes',
        subAction: 'crossArk',
        data: {
          ...crossArk,
          action: 'stopWatching',
          nodeId: nodeId
        }
      });
    },
    async onClickMonitorSetup(id) {
      const response = await this.monitorSetup({ id });
      await this.refresh();

      const downloadUrl = response.configUrl;
      console.log(downloadUrl);
      if (!downloadUrl) return false;

      const link = document.createElement('a');

      link.target = '_blank';
      link.href = downloadUrl;
      link.download = `${id}.ps1`;
      link.click();

      URL.revokeObjectURL(link.href);
    },
    async onClickMonitorConfig(id) {
      const response = await this.monitorConfig({ id });
      await this.refresh();

      const downloadUrl = response.configUrl;
      console.log(downloadUrl);
      if (!downloadUrl) return false;

      const link = document.createElement('a');

      link.target = '_blank';
      link.href = downloadUrl;
      link.download = `${id}.ps1`;
      link.click();

      URL.revokeObjectURL(link.href);
    },
    async onClickToggleMonitorEnable(id) {
      await this.updateNode({ id, data: { monitorEnabled: !this.nodes.find(node => node.id === id).monitorEnabled } });
      this.refresh();
    }
  },
  filters: {
    truncate(value, length) {
      return value.length > length ? `${value.substr(0, length)}...` : value;
    }
  },
  components: {
    NodeForm
  }
};
</script>
