const stateHelpers = require('../../lib/stateHelpers');

export default {
  GET_PRODUCTS_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'getProducts');
  },
  GET_PRODUCTS_SUCCESS: (state, { body }) => {
    stateHelpers.mutationSuccess(state, 'getProducts');

    state.products = body;
  },
  GET_PRODUCTS_ERROR: state => {
    stateHelpers.mutationError(state, 'getProducts');

    state.products = [];
  },

  // Scraper Mutations
  GET_SCRAPERS_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'getScrapers');
  },
  GET_SCRAPERS_SUCCESS: (state, { body }) => {
    stateHelpers.mutationSuccess(state, 'getScrapers');
    state.scrapers = body;
  },
  GET_SCRAPERS_ERROR: state => {
    stateHelpers.mutationError(state, 'getScrapers');

    state.scrapers = [];
  },
  EXECUTE_SCRAPERS_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'executeScrapers');
  },
  EXECUTE_SCRAPERS_SUCCESS: state => {
    stateHelpers.mutationSuccess(state, 'executeScrapers');
  },
  EXECUTE_SCRAPERS_ERROR: state => {
    stateHelpers.mutationError(state, 'executeScrapers');

    state.scrapers = [];
  },
  UPDATE_SCRAPER_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'updateScrapers');
  },
  UPDATE_SCRAPER_SUCCESS: (state, { body }) => {
    stateHelpers.mutationSuccess(state, 'updateScrapers');
    state.scrapers = state.scrapers.data.map(b => (b._id === body._id ? body : b));
  },
  UPDATE_SCRAPER_ERROR: state => {
    stateHelpers.mutationError(state, 'updateScrapers');
  },
  DELETE_SCRAPER_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'deleteScrapers');
  },
  DELETE_SCRAPER_SUCCESS: (state, { params }) => {
    stateHelpers.mutationSuccess(state, 'deleteScrapers');

    state.scrapers = state.scrapers.filter(b => b._id !== params.id);
  },
  DELETE_SCRAPER_ERROR: state => {
    stateHelpers.mutationError(state, 'deleteScrapers');
  },

  GET_NITRADO_PRODUCTS_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'getNitradoProducts');
  },
  GET_NITRADO_PRODUCTS_SUCCESS: (state, { body }) => {
    stateHelpers.mutationSuccess(state, 'getNitradoProducts');

    state.nitradoPrices = body;
  },
  GET_NITRADO_PRODUCTS_ERROR: state => {
    stateHelpers.mutationError(state, 'getNitradoProducts');

    state.nitradoPrices = [];
  },
  GET_GPORTAL_PRODUCTS_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'getGPortalProducts');
  },
  GET_GPORTAL_PRODUCTS_SUCCESS: (state, { body }) => {
    stateHelpers.mutationSuccess(state, 'getGPortalProducts');

    state.gportalPrices = body;
  },
  GET_GPORTAL_PRODUCTS_ERROR: state => {
    stateHelpers.mutationError(state, 'getGPortalProducts');

    state.gportalPrices = [];
  },
  SYNC_PRODUCTS_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'syncProducts');
  },
  SYNC_PRODUCTS_SUCCESS: state => {
    stateHelpers.mutationSuccess(state, 'syncProducts');
  },
  SYNC_PRODUCTS_ERROR: state => {
    stateHelpers.mutationError(state, 'syncProducts');
  },

  UPDATE_PRODUCT_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'updateProduct');
  },
  UPDATE_PRODUCT_SUCCESS: state => {
    stateHelpers.mutationSuccess(state, 'updateProduct');
  },
  UPDATE_PRODUCT_ERROR: state => {
    stateHelpers.mutationError(state, 'updateProduct');
  },

  UPLOAD_PRODUCT_IMAGE_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'uploadProductImage');
  },
  UPLOAD_PRODUCT_IMAGE_PROGRESS: (state, progress) => {
    state.progressAction.uploadProductImage = progress;
  },
  UPLOAD_PRODUCT_IMAGE_SUCCESS: state => {
    stateHelpers.mutationSuccess(state, 'uploadProductImage');
  },
  UPLOAD_PRODUCT_IMAGE_ERROR: state => {
    stateHelpers.mutationError(state, 'uploadProductImage');
  },

  // Blog Mutations

  GET_BLOGS_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'getBlogs');
  },
  GET_BLOGS_SUCCESS: (state, { body }) => {
    stateHelpers.mutationSuccess(state, 'getBlogs');

    state.blogs = body;
  },
  GET_BLOGS_ERROR: state => {
    stateHelpers.mutationError(state, 'getBlogs');

    state.blogs = [];
  },

  UPDATE_BLOG_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'updateBlog');
  },
  UPDATE_BLOG_SUCCESS: (state, { body }) => {
    stateHelpers.mutationSuccess(state, 'updateBlog');

    state.blogs = state.blogs.map(b => (b._id === body._id ? body : b));
  },
  UPDATE_BLOG_ERROR: state => {
    stateHelpers.mutationError(state, 'updateBlog');
  },

  CREATE_BLOG_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'createBlog');
  },
  CREATE_BLOG_SUCCESS: (state, { data }) => {
    stateHelpers.mutationSuccess(state, 'createBlog');

    state.blogs = [data, ...state.blogs];
  },
  CREATE_BLOG_ERROR: (state, { error }) => {
    stateHelpers.mutationError(state, 'createBlog', error);
  },

  DELETE_BLOG_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'deleteBlog');
  },
  DELETE_BLOG_SUCCESS: (state, { params }) => {
    stateHelpers.mutationSuccess(state, 'deleteBlog');

    state.blogs = state.blogs.filter(b => b._id !== params.id);
  },
  DELETE_BLOG_ERROR: (state, { error }) => {
    stateHelpers.mutationError(state, 'deleteBlog', error);
  },

  // Knowledgebase Mutations

  GET_KNOWLEDGEBASES_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'getKnowledgebases');
  },
  GET_KNOWLEDGEBASES_SUCCESS: (state, { body }) => {
    stateHelpers.mutationSuccess(state, 'getKnowledgebases');

    state.knowledgebases = body;
  },
  GET_KNOWLEDGEBASES_ERROR: state => {
    stateHelpers.mutationError(state, 'getKnowledgebases');

    state.knowledgebases = [];
  },

  UPDATE_KNOWLEDGEBASE_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'updateKnowledgebase');
  },
  UPDATE_KNOWLEDGEBASE_SUCCESS: (state, { body }) => {
    stateHelpers.mutationSuccess(state, 'updateKnowledgebase');

    state.knowledgebases = state.knowledgebases.map(b => (b._id === body._id ? body : b));
  },
  UPDATE_KNOWLEDGEBASE_ERROR: state => {
    stateHelpers.mutationError(state, 'updateKnowledgebase');
  },

  CREATE_KNOWLEDGEBASE_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'createKnowledgebase');
  },
  CREATE_KNOWLEDGEBASE_SUCCESS: (state, { data }) => {
    stateHelpers.mutationSuccess(state, 'createKnowledgebase');

    state.knowledgebases = [data, ...state.knowledgebases];
  },
  CREATE_KNOWLEDGEBASE_ERROR: (state, { error }) => {
    stateHelpers.mutationError(state, 'createKnowledgebase', error);
  },

  DELETE_KNOWLEDGEBASE_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'deleteKnowledgebase');
  },
  DELETE_KNOWLEDGEBASE_SUCCESS: (state, { params }) => {
    stateHelpers.mutationSuccess(state, 'deleteKnowledgebase');

    state.knowledgebases = state.knowledgebases.filter(b => b._id !== params.id);
  },
  DELETE_KNOWLEDGEBASE_ERROR: (state, { error }) => {
    stateHelpers.mutationError(state, 'deleteKnowledgebase', error);
  },

  // Promo Mutations

  GET_PROMOS_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'getPromos');
  },
  GET_PROMOS_SUCCESS: (state, { body }) => {
    stateHelpers.mutationSuccess(state, 'getPromos');

    state.promos = body;
  },
  GET_PROMOS_ERROR: state => {
    stateHelpers.mutationError(state, 'getPromos');

    state.promos = [];
  },

  UPDATE_PROMO_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'updatePromo');
  },
  UPDATE_PROMO_SUCCESS: (state, { body }) => {
    stateHelpers.mutationSuccess(state, 'updatePromo');

    state.promos = state.promos.map(b => (b._id === body._id ? body : b));
  },
  UPDATE_PROMO_ERROR: state => {
    stateHelpers.mutationError(state, 'updatePromo');
  },

  CREATE_PROMO_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'createPromo');
  },
  CREATE_PROMO_SUCCESS: (state, { data }) => {
    stateHelpers.mutationSuccess(state, 'createPromo');

    state.promos = [data, ...state.promos];
  },
  CREATE_PROMO_ERROR: (state, { error }) => {
    stateHelpers.mutationError(state, 'createPromo', error);
  },

  DELETE_PROMO_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'deletePromo');
  },
  DELETE_PROMO_SUCCESS: (state, { params }) => {
    stateHelpers.mutationSuccess(state, 'deletePromo');

    state.promos = state.promos.filter(b => b._id !== params.id);
  },
  DELETE_PROMO_ERROR: (state, { error }) => {
    stateHelpers.mutationError(state, 'deletePromo', error);
  },

  // Product Option Mutations

  GET_PRODUCT_OPTIONS_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'getProductOptions');
  },
  GET_PRODUCT_OPTIONS_SUCCESS: (state, { body }) => {
    stateHelpers.mutationSuccess(state, 'getProductOptions');

    state.productOptions = body;
  },
  GET_PRODUCT_OPTIONS_ERROR: state => {
    stateHelpers.mutationError(state, 'getProductOptions');

    state.productOptions = [];
  },

  UPDATE_PRODUCT_OPTION_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'updateProductOption');
  },
  UPDATE_PRODUCT_OPTION_SUCCESS: (state, { body }) => {
    stateHelpers.mutationSuccess(state, 'updateProductOption');

    state.productOptions = state.productOptions.map(b => (b._id === body._id ? body : b));
  },
  UPDATE_PRODUCT_OPTION_ERROR: state => {
    stateHelpers.mutationError(state, 'updateProductOption');
  },

  CREATE_PRODUCT_OPTION_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'createProductOption');
  },
  CREATE_PRODUCT_OPTION_SUCCESS: (state, { data }) => {
    stateHelpers.mutationSuccess(state, 'createProductOption');

    state.productOptions = [data, ...state.productOptions];
  },
  CREATE_PRODUCT_OPTION_ERROR: (state, { error }) => {
    stateHelpers.mutationError(state, 'createProductOption', error);
  },

  DELETE_PRODUCT_OPTION_REQUEST: state => {
    stateHelpers.mutationRequest(state, 'deleteProductOption');
  },
  DELETE_PRODUCT_OPTION_SUCCESS: (state, { params }) => {
    stateHelpers.mutationSuccess(state, 'deleteProductOption');

    state.productOptions = state.productOptions.filter(b => b._id !== params.id);
  },
  DELETE_PRODUCT_OPTION_ERROR: (state, { error }) => {
    stateHelpers.mutationError(state, 'deleteProductOption', error);
  }
};
