<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Create/edit a node</p>
    </header>
    <section class="modal-card-body">
      <b-field label="Name">
        <b-input v-model="form.name" placeholder="Enter a name" type="text" />
      </b-field>
      <b-field label="Primary IP">
        <b-input v-model="form.primaryIp" placeholder="Enter an IP" type="text" />
      </b-field>
      <b-field label="Cost">
        <b-input v-model="form.cost" placeholder="Enter the monthly cost" type="text" />
      </b-field>
      <b-field label="Date">
        <b-input v-model="form.renewalDate" placeholder="Enter renewal date" type="date" />
      </b-field>
      <b-field label="Customer Node?">
        <b-checkbox v-model="form.isCustomer"> Yes </b-checkbox>
      </b-field>
      <b-field label="Status">
        <b-select v-model="form.status">
          <option value="pending-setup" default>Pending Setup</option>
          <option value="active">Active</option>
          <option value="disabled">Disabled</option>
        </b-select>
      </b-field>
      <hr />
      <strong>IPMI Details</strong>
      <b-field label="IP Address">
        <b-input v-model="form.ipmi.ipAddress" placeholder="Enter an IP" type="text" />
      </b-field>
      <b-field label="Username">
        <b-input v-model="form.ipmi.username" placeholder="Enter a username" type="text" />
      </b-field>
      <b-field label="Password">
        <b-input placeholder="Enter a password" type="text" @input="e => (form.ipmi.password = e)" />
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Close</button>
      <button class="button is-primary" @click.prevent="onClickSubmit">Submit</button>
    </footer>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: ['isEdit', 'id', 'name', 'primaryIp', 'status', 'isCustomer', 'cost', 'renewalDate', 'ipmi'],
  data() {
    return {
      form: {}
    };
  },
  created() {
    this.form = { ...this.$props, isEdit: undefined };
  },
  methods: {
    ...mapActions({
      createNode: 'node/create',
      updateNode: 'node/update'
    }),
    async onClickSubmit() {
      if (this.isEdit) {
        await this.updateNode({ id: this.id, data: this.form });
      } else {
        await this.createNode({ data: this.form });
      }

      this.$emit('submit');

      this.$parent.close();
    }
  }
};
</script>
