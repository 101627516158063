import Vue from 'vue';

const registerInterceptors = (token, logout) => {
  Vue.http.interceptors.push((request, next) => {
    try {
      if (request.url.includes('amazonaws.com')) {
        next();
        return;
      }

      request.headers.set('Authorization', `Bearer ${token}`);

      next(res => {
        if (res.status === 401) logout();
      });
    } catch (e) {
      console.log(e);
    }
  });
};

export default registerInterceptors;
