import Vue from 'vue';
import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory('finance', 'finances', 'finance'),
  getNodeSummary: async ({ commit }) => {
    commit('GET_NODE_SUMMARY_REQUEST');
    try {
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/finance/node-summary`);

      commit('GET_NODE_SUMMARY_SUCCESS', { body });

      return body;
    } catch (e) {
      commit('GET_NODE_SUMMARY_ERROR', { error: e });
      return null;
    }
  },
  updateNodeData: async ({ commit }, params) => {
    commit('UPDATE_NODE_DATA_REQUEST', { params });

    try {
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/finance/game-servers/stats/${params.nodeName ? params.nodeName : ''}`);

      commit('UPDATE_NODE_DATA_SUCCESS', { params, body });

      return body;
    } catch (e) {
      commit('UPDATE_NODE_DATA_ERROR', { params, error: e });
      return null;
    }
  }
};
