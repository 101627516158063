import Website from './Website';
import WebsiteManageProducts from './WebsiteManageProducts';
import WebsitePrices from './WebsitePrices';
import WebsiteSyncProducts from './WebsiteSyncProducts';
import WebsiteBlogList from './WebsiteBlogList';
import WebsiteBlogEdit from './WebsiteBlogEdit';
import WebsiteKnowledgebaseList from './WebsiteKnowledgebaseList';
import WebsiteKnowledgebaseEdit from './WebsiteKnowledgebaseEdit';
import WebsitePromos from './WebsitePromos';
import WebsiteProductOptions from './WebsiteProductOptions';

export const routes = [
  {
    path: '/website-new',
    name: 'website',
    component: Website,
    meta: {
      title: 'Website'
    },
    children: [
      {
        path: 'products/sync',
        name: 'website-products-sync',
        component: WebsiteSyncProducts
      },
      {
        path: 'products/prices',
        name: 'website-products-comparison',
        component: WebsitePrices
      },
      {
        path: 'products/edit',
        name: 'website-products-edit',
        meta: { subMenu: 'edit' },
        component: WebsiteManageProducts
      },
      {
        path: 'products/edit/pricing',
        name: 'website-products-edit-pricing',
        component: WebsiteManageProducts,
        meta: { subMenu: 'pricing' }
      },
      {
        path: 'products/edit/steam',
        name: 'website-products-edit-steam',
        component: WebsiteManageProducts,
        meta: { subMenu: 'steam' }
      },
      {
        path: 'products/options',
        name: 'website-products-options',
        component: WebsiteProductOptions,
        meta: { subMenu: 'options' }
      },
      {
        path: 'blogs',
        name: 'website-blogs',
        component: WebsiteBlogList
      },
      {
        path: 'blogs/create',
        name: 'website-blogs-create',
        component: WebsiteBlogEdit
      },
      {
        path: 'knowledgebase',
        name: 'website-knowledgebase',
        component: WebsiteKnowledgebaseList
      },
      {
        path: 'knowledgebase/create',
        name: 'website-knowledgebase-create',
        component: WebsiteKnowledgebaseEdit
      },
      {
        path: 'knowledgebase/:id',
        name: 'website-knowledgebase-edit',
        component: WebsiteKnowledgebaseEdit
      },
      {
        path: 'blogs/:id',
        name: 'website-blogs-edit',
        component: WebsiteBlogEdit
      },
      {
        path: 'promos',
        name: 'website-promos',
        component: WebsitePromos
      }
    ]
  }
];

export default routes;
