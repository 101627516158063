<template>
  <div>
    <p class="title is-4">Manage Promos</p>
    <p class="subtitle is-6">Manage when promos are shown and what is shown on them.</p>

    <b-table class="mb-5" :data="promos">
      <b-table-column v-slot="props" label="Item" field="item">
        {{ props.row.label }}<br /><small>{{ props.row.location }}</small>
      </b-table-column>
      <b-table-column v-slot="props" label="Enabled" field="enabled">
        {{ props.row.enabled ? 'Yes' : 'No' }}
      </b-table-column>
      <b-table-column v-slot="props" label="Start Date" field="startDate">{{ props.row.startDate }}</b-table-column>
      <b-table-column v-slot="props" label="End Date" field="endDate">{{ props.row.endDate }}</b-table-column>
      <b-table-column v-slot="props" label="" field="actions">
        <b-button type="is-info" label="Edit" size="is-small" class="mr-3" @click="onClickEdit(props.row)" />
      </b-table-column>
    </b-table>

    <b-button v-if="!mode" class="mr-4" type="is-primary" @click="mode = 'create'">Create</b-button>

    <div v-if="mode" class="has-background-light p-5">
      <p class="title is-5">{{ mode === 'create' ? 'Create' : 'Edit' }} Promo</p>
      <b-message v-if="errorAction.createPromo" type="is-danger">
        {{ errorAction.createPromo.message || errorAction.createPromo }}
      </b-message>

      <b-message v-if="errorAction.updatePromo" type="is-danger">
        {{ errorAction.updatePromo.message || errorAction.updatePromo }}
      </b-message>

      <b-field grouped label="Label">
        <b-input v-model="form.label" expanded />
      </b-field>

      <b-field grouped label="Location" message="This must match exactly as written in the lowms-website-ui code">
        <b-input v-model="form.location" expanded />
      </b-field>

      <b-field grouped label="Type">
        <b-select v-model="form.type" expanded>
          <option value="global">Global</option>
          <option value="product">Product</option>
        </b-select>
      </b-field>

      <b-field grouped label="Enabled" message="Enable this promo">
        <b-switch v-model="form.enabled" expanded />
      </b-field>

      <b-field grouped label="Start Date">
        <b-datetimepicker v-model="form.startDate" expanded placeholder="Click to select..." icon="calendar" locale="en-GB"> </b-datetimepicker>
      </b-field>

      <b-field grouped label="End Date">
        <b-datetimepicker v-model="form.endDate" expanded placeholder="Click to select..." icon="calendar" locale="en-GB"> </b-datetimepicker>
      </b-field>

      <p class="title is-5 mb-2 mt-4">Metadata</p>
      <p class="mb-4">Different promo locations will require different amounts of content.</p>

      <b-field grouped label="Title">
        <b-input v-model="form.metadata.title" expanded />
      </b-field>

      <b-field grouped label="Sub-title">
        <b-input v-model="form.metadata.subTitle" expanded />
      </b-field>

      <b-field grouped label="Image">
        <b-input v-model="form.metadata.promo" expanded />
      </b-field>

      <b-field grouped label="Logo" message="A font awesome 6 icon class (e.g. fa-percent)">
        <b-input v-model="form.metadata.logo" expanded />
      </b-field>

      <b-field grouped label="Discount">
        <b-input v-model="form.metadata.discount" expanded />
      </b-field>

      <b-field label="Linked Product" message="If type 'Product' is selected above, this promo will only show for this product">
        <b-select v-model="form.metadata.productId" placeholder="Select a product" :loading="loadingAction.getProducts">
          <optgroup v-for="(products, gid) in groupedProducts" :key="gid" :label="`GID: ${gid}`">
            <option v-for="product in products" :key="product._id" :value="product._id">
              {{ product.name }}
            </option>
          </optgroup>
        </b-select>
      </b-field>

      <!-- <p class="title is-5 mb-2 mt-4">WHMCS</p>
      <p class="mb-4">The promo code in WHMCS.</p>

      <b-field grouped label="Code">
        <b-input v-model="form.whmcs.code" expanded />
      </b-field>

      <b-field grouped label="Amount">
        <b-input v-model="form.whmcs.amount" expanded />
      </b-field>

      <b-field grouped label="Type">
        <b-input v-model="form.whmcs.type" expanded />
      </b-field> -->

      <div class="is-flex is-justify-content-space-between">
        <span>
          <b-button
            class="mr-4"
            type="is-primary"
            :loading="loadingAction[mode === 'create' ? 'createPromo' : 'updatePromo']"
            :disabled="loadingAction[mode === 'create' ? 'createPromo' : 'updatePromo']"
            @click="onSubmit"
            >Submit</b-button
          >

          <b-button class="mr-4" type="is-default" @click="onCancel">Cancel</b-button></span
        >

        <b-button
          v-if="mode === 'edit'"
          type="is-danger"
          :loading="loadingAction.deletePromo"
          :disabled="loadingAction.deletePromo"
          @click="() => onClickDelete(form)"
          >Delete</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'WebsitePromos',
  data() {
    return {
      mode: null,
      form: {
        metadata: {},
        whmcs: {}
      }
    };
  },
  computed: {
    ...mapGetters({
      promos: 'websiteNew/promos',
      products: 'websiteNew/products',
      loadingAction: 'websiteNew/loadingAction',
      errorAction: 'websiteNew/errorAction'
    }),
    groupedProducts() {
      return this.products.reduce((acc, product) => {
        const group = product.gid;
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(product);
        return acc;
      }, {});
    }
  },
  async mounted() {
    this.getProducts();
    this.refresh();
  },
  methods: {
    ...mapActions({
      getPromos: 'websiteNew/getPromos',
      updatePromo: 'websiteNew/updatePromo',
      createPromo: 'websiteNew/createPromo',
      deletePromo: 'websiteNew/deletePromo',
      getProducts: 'websiteNew/getProducts'
    }),
    async refresh() {
      this.getPromos();
    },
    async onClickEdit(promo) {
      this.mode = 'edit';
      this.form = { ...promo, metadata: { ...(promo.metadata || {}) } };
    },
    async onClickDelete(promo) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this promo?',
        onConfirm: () => this.onDelete(promo._id)
      });
    },
    async onDelete(id) {
      await this.deletePromo({ params: { id } });

      this.refresh();
    },
    onCancel() {
      this.form = {
        metadata: {},
        whmcs: {}
      };

      this.mode = null;
    },
    async onSubmit() {
      const body = {
        data: this.form
      };

      const updatedPromo = this.mode === 'create' ? await this.createPromo(body) : await this.updatePromo({ ...body, params: { id: this.form._id } });

      if (updatedPromo && updatedPromo._id) {
        this.$buefy.toast.open({
          message: 'Promo created/updated!',
          type: 'is-success'
        });
      } else {
        this.$buefy.toast.open({
          message: 'Promo could not be created/updated',
          type: 'is-danger'
        });
      }

      if (this.mode === 'create') {
        this.form = {};
      }

      this.refresh();
      this.mode = null;
    }
  }
};
</script>
<style lang="scss" scoped>
.preview {
  white-space: pre-line;
}
</style>
