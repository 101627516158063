import Account from './Account';

export const routes = [
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      title: 'Account'
    }
  }
];

export default routes;
