import Vue from 'vue';
import Router from 'vue-router';

import authGuard from '../auth/authGuard';
import isAdmin from '../auth/isAdmin';
import Main from '../containers/Main';

import { routes as accountRoutes } from '../containers/Account';
import { routes as backupRoutes } from '../containers/Backups';
import { routes as modRoutes } from '../containers/Mods';
import { routes as financeRoutes } from '../containers/Finance';
import { routes as nodeRoutes } from '../containers/Node';
import { routes as websiteRoutes } from '../containers/Website';
import { routes as websiteNewRoutes } from '../containers/WebsiteNew';

Vue.use(Router);

const routes = [
  {
    path: '/',
    beforeEnter: authGuard(),
    component: Main,
    children: [...accountRoutes, ...backupRoutes, ...modRoutes]
  },
  {
    path: '/',
    beforeEnter: isAdmin(),
    component: Main,
    children: [...financeRoutes, ...websiteRoutes, ...websiteNewRoutes, ...nodeRoutes]
  }
];

const router = new Router({
  mode: 'history',
  routes
});

export default router;
