<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    form: {
      handler() {
        this.$emit('input', this.form);
      },
      deep: true
    }
  },
  mounted() {
    if (Object.keys(this.value).length > 0) {
      Object.keys(this.value).forEach(key => {
        this.$set(this.form, key, this.value[key]);
      });
    } else {
      this.$emit('input', this.form);
    }
  }
};
</script>
