import Vue from 'vue';
import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory('mod', 'mods', 'mod'),
  scrapeMod: async ({ commit }, params) => {
    try {
      commit('SCRAPE_MOD_DATA_REQUEST', { params });
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/mods/scrape`, {
        data: { params }
      });
      commit('SCRAPE_MOD_DATA_SUCCESS', { params, body });
      return body;
    } catch (e) {
      commit('SCRAPE_MOD_DATA_ERROR', { params, error: e });
      return null;
    }
  },
  callAPI: async ({ commit }, params) => {
    try {
      commit('SCRAPE_MOD_DATA_REQUEST', { params });
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/mods/api`, {
        data: { params }
      });
      commit('SCRAPE_MOD_DATA_SUCCESS', { params, body });
      return body;
    } catch (e) {
      commit('SCRAPE_MOD_DATA_ERROR', { params, error: e });
      return null;
    }
  },
  getMods: async ({ commit }, params) => {
    try {
      commit('GET_MODS_DATA_REQUEST', { params });
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/mods/game/${params.gameName}`);
      commit('GET_MODS_DATA_SUCCESS', { params, body });

      return body;
    } catch (e) {
      commit('GET_MODS_DATA_ERROR', { params, error: e });
      return null;
    }
  },
  uploadMod: async ({ commit }, params) => {
    try {
      commit('UPLOAD_MOD_DATA_REQUEST', { params });
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/mods/uploadMod`, {
        data: { params }
      });
      commit('UPLOAD_MOD_DATA_SUCCESS', { params });
      return body;
    } catch (e) {
      commit('UPLOAD_MOD_DATA_ERROR', { params, error: e });
      return null;
    }
  },
  getGames: async ({ commit }, params) => {
    try {
      commit('GET_GAMES_DATA_REQUEST', { params });
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/mods/getGames`);
      commit('GET_GAMES_DATA_SUCCESS', { params, body });

      return body;
    } catch (e) {
      commit('GET_GAMES_DATA_ERROR', { params, error: e });
      return null;
    }
  }
};
