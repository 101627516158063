<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Create/edit scraper</p>
    </header>

    <section class="modal-card-body">
      <b-field label="Name">
        <b-input v-model="form.company" placeholder="Enter a name" type="text" :disabled="isEdit == true" />
      </b-field>

      <b-field label="URLs">
        <b-taginput v-model="form.urls" ellipsis icon="label" placeholder="Add a url" aria-close-label="Delete this tag"> </b-taginput>
      </b-field>

      <b-field label="Type">
        <b-input v-model="form.type" placeholder="Enter a type" type="text" :disabled="isEdit == true" />
      </b-field>
    </section>

    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Close</button>

      <button class="button is-primary" @click.prevent="onClickSubmit">Submit</button>
    </footer>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: ['item', 'isEdit'],
  data() {
    return {
      form: {}
    };
  },
  mounted() {
    this.form = { ...this.$props.item };
  },
  methods: {
    ...mapActions({
      updateScraper: 'websiteNew/updateScraper'
    }),
    async onClickSubmit() {
      if (!this.form.urls) {
        this.form.urls = [''];
      }
      this.form.user = this.$auth.user.name;
      const data = await this.updateScraper(this.form);
      console.log(data);
      if (data == 'Updated') {
        this.$buefy.toast.open({
          duration: 5000,
          message: `Scraper updated successfully`,
          position: 'is-bottom',
          type: 'is-success'
        });
        this.$emit('submit');
        this.$parent.close();
      } else {
        console.log(data.body);
        this.$buefy.toast.open({
          duration: 5000,
          message: `Error updating scraper: ${data.body.message}`,
          position: 'is-bottom',
          type: 'is-danger'
        });
      }
    }
  }
};
</script>
