<template>
  <div>
    <b-field grouped label="Name">
      <b-input v-model="form.name" expanded />
    </b-field>
    <b-field grouped label="Description">
      <b-input v-model="form.description" type="textarea" expanded />
    </b-field>
    <b-field grouped label="Category">
      <b-select v-model="form.core">
        <option value="core">Core</option>
      </b-select>
    </b-field>
  </div>
</template>
<script>
import Form from '@/components/forms/Form.vue';

export default {
  extends: Form,
  data() {
    return {
      form: {
        name: '',
        description: ''
      }
    };
  }
};
</script>
