// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Vue from 'vue';
import Vuex from 'vuex';
import Buefy from 'buefy';
import VueHead from 'vue-head';
import VueResource from 'vue-resource';
import { install as VueMarkdown } from 'vue-markdown/src/build';
import VueNativeSock from 'vue-native-websocket';
import VueToasted from 'vue-toasted';

import App from './App';
import router from './router';
import store from './store';
import { Auth0Plugin } from './auth';
import { domain, clientId, audience } from './authConfig.json';

import './assets/_scss/main.scss';

import 'v-markdown-editor/dist/v-markdown-editor.css';

import Editor from 'v-markdown-editor';

// global register
Vue.use(Editor);

// Font Awesome

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  }
});

Vue.use(Vuex);
Vue.use(VueHead);
Vue.use(VueMarkdown);
Vue.use(Buefy, {
  defaultIconPack: 'fa-light'
});
Vue.use(VueResource);
Vue.use(VueToasted);

Vue.use(VueNativeSock, process.env.VUE_APP_MONITOR_URL, {
  connectManually: true,
  format: 'json'
});

Vue.config.productionTip = false;

/* eslint-disable no-new */
const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

if (window.Cypress) {
  window.__app__ = app;
}
