import Vue from 'vue';

export default {
  getProducts: async ({ commit }, params = {}) => {
    commit('GET_PRODUCTS_REQUEST', params);
    try {
      const { body } = await Vue.http.get(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/get-products`, { params });

      commit('GET_PRODUCTS_SUCCESS', { ...params, body });
      return body;
    } catch (e) {
      commit('GET_PRODUCTS_ERROR', { ...params, error: e });
      return null;
    }
  },
  getScrapers: async ({ commit }) => {
    try {
      commit('GET_SCRAPERS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/scrapers`);
      commit('GET_SCRAPERS_SUCCESS', { body });
      return body;
    } catch (e) {
      commit('GET_SCRAPERS_ERROR', { error: e });
      return null;
    }
  },
  updateScraper: async ({ commit }, params) => {
    commit('UPDATE_SCRAPER_REQUEST', { params });
    try {
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_API_URL}/scrapers`, {
        company: params.company,
        urls: params.urls,
        type: params.type
      });
      commit('UPDATE_SCRAPER_SUCCESS', { body });

      return 'Updated';
    } catch (e) {
      commit('UPDATE_SCRAPER_ERROR', { params, error: e });
      return e;
    }
  },
  deleteScraper: async ({ commit }, params) => {
    commit('DELETE_SCRAPER_REQUEST', { params });
    try {
      await Vue.http.delete(`${process.env.VUE_APP_API_URL}/scrapers/${params.id}`);
      commit('DELETE_SCRAPER_SUCCESS', { params });

      return 'Updated';
    } catch (e) {
      commit('DELETE_SCRAPER_ERROR', { params, error: e });
      return e;
    }
  },
  executeScraper: async ({ commit }, params) => {
    commit('EXECUTE_SCRAPER_REQUEST', { params });
    try {
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/scrapers/${params.id}`, {
        company: params.company,
        urls: params.urls,
        type: params.type
      });
      commit('EXECUTE_SCRAPER_SUCCESS', { body });

      return body;
    } catch (e) {
      commit('EXECUTE_SCRAPER_ERROR', { params, error: e });
      return e;
    }
  },
  getPrices: async ({ commit }) => {
    commit('GET_NITRADO_PRODUCTS_REQUEST');
    try {
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/prices`);

      commit('GET_NITRADO_PRODUCTS_SUCCESS', { body });

      return body;
    } catch (e) {
      commit('GET_NITRADO_PRODUCTS_ERROR', { error: e });
      return null;
    }
  },
  getGPortalProducts: async ({ commit }) => {
    commit('GET_GPORTAL_PRODUCTS_REQUEST');
    try {
      const query = `
  query GetProduct($locale: String!, $slugs: [String], $currency: String!) {
    products(slug_match: $slugs, locale: $locale, currency: $currency) {
      name
      content {
        ... on StrapiOrderConfigurations {
          orderConfigurations {
            duration
            units
            unitType
            locations {
              available
              key
              prices {
                value
                currency
                __typename
              }
              orderUrl
              slug
              country
              countryCode
              city
              __typename
            }
          }
        }
      }
    }
  }
`;

      const variables = {
        locale: 'en',
        slugs: ['.*'],
        currency: 'GBP'
      };

      const payload = {
        query,
        variables
      };

      const { body } = await Vue.http.post('https://www.g-portal.com/graphql/query', payload);
      const products = body.data.products;

      const productPrices = products.map(product => {
        const productName = product.name;

        if (product.content[0].orderConfigurations) {
          const cheapestConfig = product.content[0].orderConfigurations
            .filter(config => config.unitType === 'Slot' && config.duration == 30)
            .reduce((cheapest, config) => {
              if (!cheapest || config.units < cheapest.units) {
                return config;
              }
              return cheapest;
            }, null);

          if (cheapestConfig) {
            const locations = cheapestConfig.locations.filter(location => {
              return location.city === 'London';
            });
            if (locations.length === 0) {
              return {
                title: productName,
                perSlot: '',
                content: product.content[0]
              };
            } else {
              const perSlot = (locations[0].prices[0].value / 100 / cheapestConfig.units).toFixed(2);
              return {
                title: productName,
                perSlot: perSlot
              };
            }
          } else {
            return {
              title: productName,
              perSlot: '',
              content: product.content[0]
            };
          }
        } else {
          return {
            title: productName,
            perSlot: '',
            content: product.content[0]
          };
        }
      });
      commit('GET_GPORTAL_PRODUCTS_SUCCESS', { productPrices });

      return productPrices;
    } catch (e) {
      console.log(e);
      commit('GET_GPORTAL_PRODUCTS_ERROR', { error: e });
      return null;
    }
  },
  syncProducts: async ({ commit }, params = {}) => {
    commit('SYNC_PRODUCTS_REQUEST', params);

    try {
      const { body } = await Vue.http.get(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/sync-products`, { params });

      commit('SYNC_PRODUCTS_SUCCESS', { ...params, body });

      return body;
    } catch (e) {
      commit('SYNC_PRODUCTS_ERROR', { ...params, error: e.body });
      return null;
    }
  },
  updateProduct: async ({ commit }, { pid, data }) => {
    commit('UPDATE_PRODUCT_REQUEST', { pid });

    try {
      const { body } = await Vue.http.post(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/update-product?pid=${pid}`, data);

      commit('UPDATE_PRODUCT_SUCCESS', { pid, body });

      return body;
    } catch (e) {
      commit('UPDATE_PRODUCT_ERROR', { pid, error: e.body });
      return null;
    }
  },
  uploadProductImage: async ({ commit }, { name, file }) => {
    commit('UPLOAD_PRODUCT_IMAGE_REQUEST');

    try {
      const { body } = await Vue.http.post(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/upload-product-image?name=${name}`, file, {
        progress(e) {
          commit('UPLOAD_PRODUCT_IMAGE_PROGRESS', (e.loaded / e.total) * 100);
        }
      });

      commit('UPLOAD_PRODUCT_IMAGE_SUCCESS', body);

      return body;
    } catch (e) {
      commit('UPLOAD_PRODUCT_IMAGE_ERROR', e.body);
    }
  },

  // Blog Actions
  getBlogs: async ({ commit }, params = {}) => {
    commit('GET_BLOGS_REQUEST', params);
    try {
      const { body } = await Vue.http.get(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/get-blogs`, { params });

      commit('GET_BLOGS_SUCCESS', { ...params, body });

      return body;
    } catch (e) {
      commit('GET_BLOGS_ERROR', { ...params, error: e });
      return null;
    }
  },
  updateBlog: async ({ commit }, { params, data }) => {
    commit('UPDATE_BLOG_REQUEST', params);

    try {
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/update-blog`, data, { params });

      commit('UPDATE_BLOG_SUCCESS', { ...params, body });

      return body;
    } catch (e) {
      commit('UPDATE_BLOG_ERROR', { ...params, error: e.body });
      return null;
    }
  },
  createBlog: async ({ commit }, { data }) => {
    commit('CREATE_BLOG_REQUEST', { data });

    try {
      const { body } = await Vue.http.post(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/create-blog`, data);

      commit('CREATE_BLOG_SUCCESS', { data });

      return body;
    } catch (e) {
      commit('CREATE_BLOG_ERROR', { error: e.body });
      return null;
    }
  },
  deleteBlog: async ({ commit }, { params }) => {
    commit('DELETE_BLOG_REQUEST', { params });

    try {
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/delete-blog`, { params });

      commit('DELETE_BLOG_SUCCESS', { params });

      return body;
    } catch (e) {
      commit('DELETE_BLOG_ERROR', { error: e.body });
      return null;
    }
  },

  // Knowledgebase Actions
  getKnowledgebases: async ({ commit }, params = {}) => {
    commit('GET_KNOWLEDGEBASES_REQUEST', params);
    try {
      const { body } = await Vue.http.get(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/get-knowledgebases`, { params });

      commit('GET_KNOWLEDGEBASES_SUCCESS', { ...params, body });

      return body;
    } catch (e) {
      commit('GET_KNOWLEDGEBASES_ERROR', { ...params, error: e });
      return null;
    }
  },
  updateKnowledgebase: async ({ commit }, { params, data }) => {
    commit('UPDATE_KNOWLEDGEBASE_REQUEST', params);

    try {
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/update-knowledgebase`, data, { params });

      commit('UPDATE_KNOWLEDGEBASE_SUCCESS', { ...params, body });

      return body;
    } catch (e) {
      commit('UPDATE_KNOWLEDGEBASE_ERROR', { ...params, error: e.body });
      return null;
    }
  },
  createKnowledgebase: async ({ commit }, { data }) => {
    commit('CREATE_KNOWLEDGEBASE_REQUEST', { data });

    try {
      const { body } = await Vue.http.post(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/create-knowledgebase`, data);

      commit('CREATE_KNOWLEDGEBASE_SUCCESS', { data });

      return body;
    } catch (e) {
      commit('CREATE_KNOWLEDGEBASE_ERROR', { error: e.body });
      return null;
    }
  },
  deleteKnowledgebase: async ({ commit }, { params }) => {
    commit('DELETE_KNOWLEDGEBASE_REQUEST', { params });

    try {
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/delete-knowledgebase`, { params });

      commit('DELETE_KNOWLEDGEBASE_SUCCESS', { params });

      return body;
    } catch (e) {
      commit('DELETE_KNOWLEDGEBASE_ERROR', { error: e.body });
      return null;
    }
  },

  // Promo Actions
  getPromos: async ({ commit }, params = {}) => {
    commit('GET_PROMOS_REQUEST', params);
    try {
      const { body } = await Vue.http.get(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/get-promos`, { params });

      commit('GET_PROMOS_SUCCESS', { ...params, body });

      return body;
    } catch (e) {
      commit('GET_PROMOS_ERROR', { ...params, error: e });
      return null;
    }
  },
  updatePromo: async ({ commit }, { params, data }) => {
    commit('UPDATE_PROMO_REQUEST', params);

    try {
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/update-promo`, data, { params });

      commit('UPDATE_PROMO_SUCCESS', { ...params, body });

      return body;
    } catch (e) {
      commit('UPDATE_PROMO_ERROR', { ...params, error: e.body });
      return null;
    }
  },
  createPromo: async ({ commit }, { data }) => {
    commit('CREATE_PROMO_REQUEST', { data });

    try {
      const { body } = await Vue.http.post(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/create-promo`, data);

      commit('CREATE_PROMO_SUCCESS', { data });

      return body;
    } catch (e) {
      commit('CREATE_PROMO_ERROR', { error: e.body });
      return null;
    }
  },
  deletePromo: async ({ commit }, { params }) => {
    commit('DELETE_PROMO_REQUEST', { params });

    try {
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/delete-promo`, { params });

      commit('DELETE_PROMO_SUCCESS', { params });

      return body;
    } catch (e) {
      commit('DELETE_PROMO_ERROR', { error: e.body });
      return null;
    }
  },

  // Product Option Actions
  getProductOptions: async ({ commit }, params = {}) => {
    commit('GET_PRODUCT_OPTIONS_REQUEST', params);
    try {
      const { body } = await Vue.http.get(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/get-product-options`, { params });

      commit('GET_PRODUCT_OPTIONS_SUCCESS', { ...params, body });

      return body;
    } catch (e) {
      commit('GET_PRODUCT_OPTIONS_ERROR', { ...params, error: e });
      return null;
    }
  },
  updateProductOption: async ({ commit }, { params, data }) => {
    commit('UPDATE_PRODUCT_OPTION_REQUEST', params);

    try {
      const { body } = await Vue.http.patch(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/update-product-option`, data, { params });

      commit('UPDATE_PRODUCT_OPTION_SUCCESS', { ...params, body });

      return body;
    } catch (e) {
      commit('UPDATE_PRODUCT_OPTION_ERROR', { ...params, error: e.body });
      return null;
    }
  },
  createProductOption: async ({ commit }, { data }) => {
    commit('CREATE_PRODUCT_OPTION_REQUEST', { data });

    try {
      const { body } = await Vue.http.post(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/create-product-option`, data);

      commit('CREATE_PRODUCT_OPTION_SUCCESS', { data });

      return body;
    } catch (e) {
      commit('CREATE_PRODUCT_OPTION_ERROR', { error: e.body });
      return null;
    }
  },
  deleteProductOption: async ({ commit }, { params }) => {
    commit('DELETE_PRODUCT_OPTION_REQUEST', { params });

    try {
      const { body } = await Vue.http.delete(`${process.env.VUE_APP_LOWMS_WEBSITE_FUNCTIONS_URL}/delete-product-option`, { params });

      commit('DELETE_PRODUCT_OPTION_SUCCESS', { params });

      return body;
    } catch (e) {
      commit('DELETE_PRODUCT_OPTION_ERROR', { error: e.body });
      return null;
    }
  }
};
