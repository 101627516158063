import Vue from 'vue';
import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory('node', 'nodes'),
  CHECK_DNS_REQUEST: (state, { id }) => {
    state.loadingAction.checkDns = id;
    state.checkDnsError[id] = null;
    state.checkDnsResponse[id] = null;
  },
  CHECK_DNS_SUCCESS: (state, { id, body }) => {
    state.loadingAction.checkDns = false;
    state.checkDnsError[id] = null;
    state.checkDnsResponse[id] = body;
  },
  CHECK_DNS_ERROR: (state, { id, error }) => {
    state.loadingAction.checkDns = false;
    state.checkDnsError[id] = error;
    state.checkDnsResponse[id] = null;
  },

  PROVISION_NODE_REQUEST: (state, { id }) => {
    state.loadingAction.provisionNode = id;
  },
  PROVISION_NODE_SUCCESS: state => {
    state.loadingAction.provisionNode = false;
  },
  PROVISION_NODE_ERROR: state => {
    state.loadingAction.provisionNode = false;
  },

  MONITOR_SETUP_REQUEST: (state, { id }) => {
    state.loadingAction.monitorSetup = id;
  },
  MONITOR_SETUP_SUCCESS: (state, { id, body }) => {
    state.loadingAction.monitorSetup = false;

    const node = state.nodes.find(node => node._id === id);
    node.monitorConfigUrl = body.configUrl;
  },
  MONITOR_SETUP_ERROR: state => {
    state.loadingAction.monitorSetup = false;
  },

  MONITOR_CONFIG_REQUEST: (state, { id }) => {
    state.loadingAction.monitorConfig = id;
  },
  MONITOR_CONFIG_SUCCESS: (state, { id, body }) => {
    state.loadingAction.monitorConfig = false;

    const node = state.nodes.find(node => node._id === id);
    node.monitorConfigUrl = body.configUrl;
  },
  MONITOR_CONFIG_ERROR: state => {
    state.loadingAction.monitorConfig = false;
  },

  START_MONITORING: state => {
    state.monitoring.nodes = [...state.nodes];
    state.monitoring.enabled = true;
  },

  STOP_MONITORING: state => {
    state.monitoring.nodes = [];
    state.monitoring.enabled = false;
  },

  REFRESH_MONITORING: state => {
    state.monitoring.nodes = state.monitoring.nodes.map(node => {
      const originalNode = state.nodes.find(n => n._id === node._id);

      return {
        ...node,
        ...originalNode
      };
    });
  },

  HANDLE_MONITORING_RESPONSE: (state, message) => {
    const node = state.monitoring.nodes.find(node => node._id === message.requestBy.principalId);

    Vue.set(node, message.response.type, message.response);
  }
};
