import actions from './actions';
import mutations from './mutations';
import crudActions from '../../lib/crudActions';

const store = {
  namespaced: true,
  state: {
    loading: false,
    error: '',
    total: 0,
    mods: [],
    mod: {},
    games: [],
    loadingAction: {
      ...crudActions
    }
  },
  getters: {
    mods: state => state.mods,
    mod: state => state.mod,
    games: state => state.games,
    modRules: state => state.modRules,
    total: state => state.total,
    loading: state => state.loading,
    error: state => state.error,
    loadingAction: state => state.loadingAction
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
