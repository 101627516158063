import Finance from './Finance';

export const routes = [
  {
    path: '/finance',
    name: 'Finance',
    component: Finance,
    meta: {
      title: 'Finance'
    }
  }
];

export default routes;
