import Node from './Node';

export const routes = [
  {
    path: '/nodes',
    name: 'Node',
    component: Node,
    meta: {
      title: 'Node'
    }
  }
];

export default routes;
