<template>
  <div class="section">
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <h1>Account</h1>
        <p>You are logged in as {{ $auth.user.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Account'
};
</script>
