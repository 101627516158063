<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Create/edit mod</p>
    </header>
    <section class="modal-card-body">
      <b-field label="Mod Name">
        <b-input v-model="form.display_name" placeholder="Enter a name" type="text" />
      </b-field>
      <b-field label="Group Name">
        <b-input v-model="form.group_name" type="text" />
      </b-field>
      <b-field label="Extract Path">
        <b-input v-model="form.extract_path" type="text" />
      </b-field>
      <b-field label="Comments">
        <b-input v-model="form.comments" type="text" />
      </b-field>
      <b-field label="Help URL">
        <b-input v-model="form.help_url" type="text" />
      </b-field>
      <b-field label="Homepage URL">
        <b-input v-model="form.homepage_url" type="text" />
      </b-field>
      <b-field label="Download URL">
        <b-input v-model="form.win_filename" type="text" />
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Close</button>
      <button class="button is-primary" @click.prevent="onClickSubmit">Submit</button>
    </footer>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: ['item'],
  data() {
    return {
      form: {}
    };
  },
  mounted() {
    this.form = { ...this.$attrs };
  },
  methods: {
    ...mapActions({
      uploadModToTCAdmin: 'mod/uploadMod'
    }),
    async onClickSubmit() {
      const response = await this.uploadModToTCAdmin({ game: this.form.game, APIMod: this.form });
      if (response == 'Updated') {
        this.$emit('submit');
        this.$parent.close();
      }

      this.$emit('submit');
      this.$parent.close();
    }
  }
};
</script>
