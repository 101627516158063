export const mutationRequest = (state, id) => {
  state.loadingAction[id] = true;
  state.errorAction[id] = false;
};

export const mutationSuccess = (state, id) => {
  state.loadingAction[id] = false;
  state.errorAction[id] = false;
};

export const mutationError = (state, id, error) => {
  state.loadingAction[id] = false;
  state.errorAction[id] = error || true;
};
