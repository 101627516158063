<template>
  <div>
    <b-field grouped label="Name" message="Internal name e.g. Slots">
      <b-input v-model="form.name" />
    </b-field>
    <b-field grouped label="Public Name" message="Public facing name e.g. Player Slots">
      <b-input v-model="form.title" />
    </b-field>
    <b-field grouped label="Unique ID/Code" message="Unique code for this option e.g. slots">
      <b-input v-model="form.code" />
    </b-field>
    <b-field grouped label="Description" message="Public facing description for this option">
      <b-input v-model="form.description" type="textarea" expanded />
    </b-field>
    <b-field grouped label="Type" message="The UI element shown on the order form">
      <b-select v-model="form.type">
        <option value="select" selected>Select</option>
        <option value="categories">Categories</option>
        <option value="slider">Slider</option>
      </b-select>
    </b-field>
    <b-field grouped label="Value Type" message="The type of value">
      <b-select v-model="form.valueType">
        <option value="number" selected>Number</option>
        <option value="discount">Discount</option>
      </b-select>
    </b-field>

    <b-field grouped label="Pricing (Copy and paste from Multi-month Google sheet)">
      <b-input v-model="rawPricing" type="textarea" expanded />
    </b-field>

    <div v-if="form.items && Array.isArray(form.items)">
      <div v-for="item in form.items" :key="item.code">{{ item.text }} slots = £{{ item.value }}</div>
    </div>
  </div>
</template>
<script>
import Form from '@/components/forms/Form.vue';

export default {
  extends: Form,
  data() {
    return {
      form: {
        code: '',
        name: '',
        title: '',
        category: '',
        description: '',
        type: 'select',
        valueType: 'number',
        items: []
      },
      rawPricing: '',
      pricing: []
    };
  },
  watch: {
    rawPricing() {
      if (!this.rawPricing) return [];

      try {
        const rows = this.rawPricing.split('\n');

        this.form.items = rows
          .map(row => {
            const [slots, valueRaw, basketText, multiplier, pos1, pos2] = row.split('\t');

            const value = parseFloat(valueRaw, 10);

            const pos = [];

            if (pos1) pos.push(pos1);
            if (pos2) pos.push(pos2);

            return {
              code: slots,
              text: slots,
              value,
              basketText,
              multiplier,
              pos
            };
          })
          .filter(a => a);
      } catch (e) {
        return e;
      }
    }
  }
};
</script>
