<template>
  <div class="container">
    <section>
      <b-modal :active.sync="isEditModal" has-modal-card :can-cancel="['escape', 'x']" scroll="keep">
        <WebsiteGameForm v-bind="editProps" @submit="refresh" />
      </b-modal>
      <b-modal :active.sync="isDeployModal" has-modal-card :can-cancel="['escape', 'x']" scroll="keep">
        <WebsiteDeployModal />
      </b-modal>
      <div>
        <b-button @click.prevent="onClickCreate"> Create </b-button>
        <b-button class="is-primary" @click.prevent="onClickDeploy"> Deploy </b-button>
      </div>
      <b-field label="Search">
        <b-input v-model="search" @input="refresh" />
      </b-field>
      <b-table
        ref="table"
        :data="games"
        :loading="loadingAction.getGames"
        :total="totalGames"
        :per-page="perPage"
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        paginated
        backend-pagination
        backend-sorting
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        @page-change="onPageChange"
        @sort="onSort"
      >
        <b-table-column v-slot="props" field="name" label="Name" sortable>
          {{ props.row.name }}
        </b-table-column>
        <b-table-column v-slot="props" field="featured" label="Featured?" sortable>
          {{ props.row.featured ? 'Yes' : '' }}
        </b-table-column>
        <b-table-column v-slot="props" field="spotlight" label="Spotlight" sortable>
          {{ props.row.spotlight ? 'Yes' : '' }}
        </b-table-column>
        <b-table-column v-slot="props" label="Actions" sortable>
          <b-button size="is-small" type="is-info" @click.prevent="() => onClickEdit(props.row.pid)"> Edit </b-button>
        </b-table-column>
      </b-table>
    </section>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import WebsiteGameForm from './WebsiteGameForm';
import WebsiteDeployModal from './WebsiteDeployModal';

export default {
  name: 'Website',
  components: {
    WebsiteGameForm,
    WebsiteDeployModal
  },
  data() {
    return {
      perPage: 20,
      page: 1,
      sortField: 'name',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      search: '',
      isEditModal: false,
      isDeployModal: false,
      editProps: {},
      deleting: {}
    };
  },
  computed: {
    ...mapGetters({
      games: 'website/games',
      loading: 'website/loading',
      error: 'website/error',
      loadingAction: 'website/loadingAction',
      totalGames: 'website/totalGames'
    })
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions({
      getGames: 'website/getGames'
    }),
    async refresh() {
      const params = {
        $sort: `${this.sortField}:${this.sortOrder}`,
        $limit: this.perPage,
        $skip: Math.floor((this.page - 1) * this.perPage)
      };

      if (this.search) {
        params.$search = { name: this.search };
      }

      this.getGames({
        data: {
          params
        }
      });
    },
    onPageChange(page) {
      this.page = page;
      this.refresh();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.refresh();
    },
    onClickCreate() {
      this.editProps = { isEdit: false };
      this.isEditModal = true;
    },
    onClickDeploy() {
      this.isDeployModal = true;
    },
    onClickEdit(id) {
      const game = this.games.find(game => game.pid === id);

      this.editProps = {
        steamStoreId: game.steamStoreId,
        minSlots: game.minSlots,
        billingId: id,
        isComingSoon: !!game.isComingSoon,
        featured: !!game.featured,
        spotlight: !!game.spotlight,
        isEdit: true
      };

      this.isEditModal = true;
    }
  }
};
</script>
