export default {
  GET_WEBSITE_GAMES_REQUEST: state => {
    state.loadingAction.getGames = true;
    state.totalGames = 0;
  },
  GET_WEBSITE_GAMES_SUCCESS: (state, { body }) => {
    state.loadingAction.getGames = false;
    state.games = body.data;
    state.totalGames = body.total;
  },
  GET_WEBSITE_GAMES_ERROR: state => {
    state.loadingAction.getGames = false;
    state.games = [];
    state.totalGames = 0;
  },

  CREATE_WEBSITE_GAME_REQUEST: state => {
    state.loadingAction.createGame = true;
    state.errorAction.createGame = null;
  },
  CREATE_WEBSITE_GAME_SUCCESS: state => {
    state.loadingAction.createGame = false;
    state.errorAction.createGame = null;
  },
  CREATE_WEBSITE_GAME_ERROR: (state, { error }) => {
    state.loadingAction.createGame = false;
    state.errorAction.createGame = error.data ? error.data.message : error.message;
  },

  DEPLOY_WEBSITE_REQUEST: state => {
    state.loadingAction.deploy = true;
    state.errorAction.deploy = null;
  },
  DEPLOY_WEBSITE_SUCCESS: state => {
    state.loadingAction.deploy = false;
    state.errorAction.deploy = null;
  },
  DEPLOY_WEBSITE_ERROR: (state, { error }) => {
    state.loadingAction.deploy = false;
    state.errorAction.deploy = error.data ? error.data.message : error.message;
  },

  DEPLOY_STATUS_REQUEST: state => {
    state.loadingAction.deployStatus = true;
    state.errorAction.deployStatus = null;
  },
  DEPLOY_STATUS_SUCCESS: (state, { body }) => {
    state.loadingAction.deployStatus = false;
    state.errorAction.deployStatus = null;
    state.deployStatus = body;
  },
  DEPLOY_STATUS_ERROR: (state, { error }) => {
    state.loadingAction.deployStatus = false;
    state.errorAction.deployStatus = error.data ? error.data.message : error.message;
    state.deployStatus = {};
  },
  DEPLOY_STATUS_CLEAR: state => {
    state.loadingAction.deployStatus = false;
    state.errorAction.deployStatus = null;
    state.deployStatus = {};
  }
};
