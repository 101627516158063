<template>
  <div>
    <div class="block">
      <h4 class="title is-4">Manage Products</h4>
      <p class="subtitle is-5">Manage all products on the website.</p>
      <b-field label="Selected product">
        <b-select v-model="selectedProductId" placeholder="Select a product" icon="box" :loading="loadingAction.getProducts" @input="onChangeProduct">
          <optgroup v-for="(products, gid) in groupedProducts" :key="gid" :label="`GID: ${gid}`">
            <option v-for="product in products" :key="product._id" :value="product._id">
              {{ Object.keys(product.overrides || {}).length > 0 ? '***' : '' }}{{ product.name }}
            </option>
          </optgroup>
        </b-select>
      </b-field>
    </div>
    <div v-if="selectedProductId" class="p-5 has-background-light">
      <div v-if="$route.meta.subMenu === 'edit'">
        <div class="mb-5">
          <p class="title is-4">Edit Product</p>
          <p class="subtitle is-6">
            Some fields will be overridden by WHMCS data during a sync. If you don't want this to happen, untick the "Use WHMCS" checkbox.
          </p>
          <b-field grouped label="Name">
            <b-input :value="selectedProduct.name" expanded @input="val => onPropertyChange('name', val)" />
            <p class="control">
              <b-switch :value="!selectedProduct.overrides || !selectedProduct.overrides.name" @input="val => onWHMCSOverride('name', val)"
                >Use WHMCS</b-switch
              >
            </p>
          </b-field>
          <b-field grouped label="Slug" message="Name used in the URL">
            <b-input :value="selectedProduct.slug" expanded @input="val => onPropertyChange('slug', val)" />
            <p class="control">
              <b-switch :value="!selectedProduct.overrides || !selectedProduct.overrides.slug" @input="val => onWHMCSOverride('slug', val)"
                >Use WHMCS</b-switch
              >
            </p>
          </b-field>
          <b-field grouped label="SEO Name" message="Name used for SEO purposes. Should be just the product name in URL friendly format">
            <b-input :value="selectedProduct.seoName" expanded @input="val => onPropertyChange('seoName', val)" />
            <p class="control">
              <b-switch :value="!selectedProduct.overrides || !selectedProduct.overrides.seoName" @input="val => onWHMCSOverride('seoName', val)"
                >Use WHMCS</b-switch
              >
            </p>
          </b-field>
          <b-field label="Steam App ID" message="This will be used to pull down Steam data during the next sync">
            <b-input :value="selectedProduct.steamAppId" @input="val => onPropertyChange('steamAppId', val)" />
          </b-field>
          <b-field grouped label="Description" message="">
            <b-input type="textarea" :value="selectedProduct.description" expanded @input="val => onPropertyChange('description', val)" />
            <p class="control">
              <b-switch
                :value="!selectedProduct.overrides || !selectedProduct.overrides.description"
                @input="val => onWHMCSOverride('description', val)"
                >Use WHMCS</b-switch
              >
            </p>
          </b-field>
          <b-field grouped label="Rich Description" message="">
            <b-input type="textarea" :value="selectedProduct.richDescription" expanded @input="val => onPropertyChange('richDescription', val)" />
          </b-field>
          <b-field grouped label="Game Description" message="">
            <b-input type="textarea" :value="selectedProduct.gameDescription" expanded @input="val => onPropertyChange('gameDescription', val)" />
          </b-field>
          <b-field grouped label="SEO Description (Meta Tags Only)" message="">
            <b-input type="textarea" :value="selectedProduct.seoDescription" expanded @input="val => onPropertyChange('seoDescription', val)" />
          </b-field>
          <b-field grouped label="Youtube Video URL" message="">
            <b-input :value="selectedProduct.videoUrl" expanded @input="val => onPropertyChange('videoUrl', val)" />
          </b-field>
          <div class="columns">
            <div class="column">
              <b-field grouped label="FAQ - Question" message="">
                <b-input type="textarea" :value="newFaq.question" expanded @input="val => onPropertyChange('newFAQQuestion', val)" />
              </b-field>
            </div>
            <div class="column">
              <b-field grouped label="FAQ - Answer" message="">
                <b-input type="textarea" :value="newFaq.answer" expanded @input="val => onPropertyChange('newFAQAnswer', val)" />
              </b-field>
            </div>
          </div>
          <b-button class="mb-4" type="is-primary is-fullwidth" :loading="loadingAction.updateProduct" @click="onSubmitFAQ">Submit new FAQ</b-button>
          <div v-for="(question, index) in selectedProduct.faq" :key="index" class="columns mb-5 is-inline">
            <div class="column">{{ question }}</div>
            <b-button type="is-danger" @click="onRemoveFaq(index)">Delete</b-button>
          </div>
          <b-field grouped label="SEO Description (Meta Tags Only)" message="">
            <b-input type="textarea" :value="selectedProduct.seoDescription" expanded @input="val => onPropertyChange('seoDescription', val)" />
          </b-field>
          <b-field grouped label="Visible" message="Whether or not this product is visible on the product list page.">
            <p class="control">
              <b-switch :value="selectedProduct.visible" @input="val => onPropertyChange('visible', val)">Yes</b-switch>
            </p>
          </b-field>
          <b-field grouped label="Status" message="Status of this product">
            <b-select placeholder="Select a status" :value="selectedProduct.status" @input="val => onPropertyChange('status', val)">
              <option value="available">Available</option>
              <option value="comingSoon">Coming Soon</option>
              <option value="preOrder">Pre-Order</option>
            </b-select>
          </b-field>
          <b-field
            v-if="selectedProduct.status !== 'available'"
            grouped
            label="Availability Date"
            message="The date to display for this products availability"
          >
            <p class="control">
              <b-input :value="selectedProduct.availabilityDate" @input="val => onPropertyChange('availabilityDate', val)" />
            </p>
          </b-field>
          <b-field grouped label="Show on homepage" message="Show this product on the homepage (e.g. newly release)">
            <p class="control">
              <b-switch :value="selectedProduct.showOnHomepage" @input="val => onPropertyChange('showOnHomepage', val)">Yes</b-switch>
            </p>
          </b-field>
          <b-button
            class="mb-4"
            type="is-primary is-fullwidth"
            :loading="loadingAction.updateProduct"
            :disabled="Object.keys(productChanges).length === 0"
            @click="onSubmitChanges"
            >Submit</b-button
          >
        </div>
        <p class="title is-5 mb-1">Images</p>
        <p class="mb-5">Header and background images are cached, so you may still see the old image after uploading a new image.</p>
        <div class="columns">
          <div class="column is-one-third">
            <ScreenshotUpload
              :product="selectedProduct"
              title="Header"
              size="is-2by1"
              :current="selectedProduct.images ? selectedProduct.images.header : null"
              type="header"
              @upload="onUploadImage"
            />
          </div>
          <div class="column is-one-third">
            <ScreenshotUpload
              :product="selectedProduct"
              title="Background"
              :current="selectedProduct.images ? selectedProduct.images.background : null"
              type="background"
              @upload="onUploadImage"
            />
          </div>
          <div class="column is-one-third">
            <ScreenshotUpload
              :product="selectedProduct"
              title="Icon (128x128)"
              :current="selectedProduct.images ? selectedProduct.images.icon : null"
              type="icon"
              @upload="onUploadImage"
            />
          </div>
        </div>

        <div class="columns">
          <div class="column is-one-third">
            <ScreenshotUpload :product="selectedProduct" title="Logo" :current="selectedProduct.images.logo" type="logo" @upload="onUploadImage" />
            <b-button type="is-danger" @click="onRemoveImageLogo('logo')">Delete</b-button>
          </div>
          <div class="column is-one-third">
            <ScreenshotUpload
              :product="selectedProduct"
              title="Developer Logo"
              :current="selectedProduct.images.developerLogo"
              type="developerLogo"
              @upload="onUploadImage"
            />
            <b-button type="is-danger" @click="onRemoveImageLogo('developerLogo')">Delete</b-button>
          </div>
        </div>
        <div
          v-for="(screenshot, index) in selectedProduct.images ? (selectedProduct.images ? selectedProduct.images.screenshots : []) : []"
          :key="index"
          class="mb-5"
        >
          <p class="title is-6 mb-2">Screenshot {{ index }}</p>
          <figure class="image 16by9 mb-2">
            <img :src="screenshot.thumb" />
          </figure>
          <b-button type="is-danger" @click="onRemoveImage(index)">Delete</b-button>
          <hr />
        </div>

        <div class="mb-5">
          <ScreenshotUpload :product="selectedProduct" :title="`New Screenshot`" @upload="onUploadImage" />
        </div>
      </div>
      <div v-if="$route.meta.subMenu === 'pricing'" class="mb-5">
        <p class="title is-4">WHMCS Pricing Data</p>

        <div class="mb-5">
          <p class="title is-6 mb-1">Slot Pricing</p>
          <p class="mb-5">The pricing per slot. Depending on if WHMCS is using a dropdown or a slider, this may just be a single row.</p>
          <b-table :data="slotPricing">
            <b-table-column v-slot="props" field="slots" label="Slots">
              {{ props.row.slots }}
            </b-table-column>
            <b-table-column v-slot="props" field="monthly" label="Monthly">
              £{{ props.row.monthlyAmountGBP.toFixed(2) }} <small>(£{{ props.row.monthlyPerSlotGBP.toFixed(2) }})</small>
            </b-table-column>
            <b-table-column v-slot="props" field="quarterly" label="Quarterly">
              £{{ props.row.quarterlyAmountGBP.toFixed(2) }} <small>(£{{ props.row.quarterlyPerSlotGBP.toFixed(2) }})</small>
            </b-table-column>
            <b-table-column v-slot="props" field="semiannually" label="Semi-annually">
              £{{ props.row.semiannuallyAmountGBP.toFixed(2) }} <small>(£{{ props.row.semiannuallyPerSlotGBP.toFixed(2) }})</small>
            </b-table-column>
            <b-table-column v-slot="props" field="annually" label="Annually">
              £{{ props.row.annuallyAmountGBP.toFixed(2) }} <small>(£{{ props.row.annuallyPerSlotGBP.toFixed(2) }})</small>
            </b-table-column>
          </b-table>
        </div>

        <div class="mb-5">
          <p class="title is-6 mb-1">From Pricing</p>
          <p class="mb-5">This is the lowest calculated pricing available. It's used to show "From £2.00/month" on the website.</p>
          <b-table :data="fromPricing">
            <b-table-column v-slot="props" field="slots" label="Slots">
              {{ props.row.slots }}
            </b-table-column>
            <b-table-column v-slot="props" field="monthly" label="Monthly">
              £{{ props.row.monthlyAmountGBP.toFixed(2) }} <small>(£{{ props.row.monthlyPerSlotGBP.toFixed(2) }})</small>
            </b-table-column>
            <b-table-column v-slot="props" field="quarterly" label="Quarterly">
              £{{ props.row.quarterlyAmountGBP.toFixed(2) }} <small>(£{{ props.row.quarterlyPerSlotGBP.toFixed(2) }})</small>
            </b-table-column>
            <b-table-column v-slot="props" field="semiannually" label="Semi-annually">
              £{{ props.row.semiannuallyAmountGBP.toFixed(2) }} <small>(£{{ props.row.semiannuallyPerSlotGBP.toFixed(2) }})</small>
            </b-table-column>
            <b-table-column v-slot="props" field="annually" label="Annually">
              £{{ props.row.annuallyAmountGBP.toFixed(2) }} <small>(£{{ props.row.annuallyPerSlotGBP.toFixed(2) }})</small>
            </b-table-column>
          </b-table>
        </div>
      </div>
      <div v-if="$route.meta.subMenu === 'steam'" class="mb-5">
        <p class="title is-4">Steam Data</p>
        <p class="subtitle is-6">
          Steam data can be used on product pages. It's accessible from the product.steamData property. This data gets refreshed when the products are
          synced.
        </p>
        <pre class="has-background-dark has-text-warning">{{ selectedProduct.steamData }}</pre>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ScreenshotUpload from '../../components/ScreenshotUpload.vue';

export default {
  name: 'WebsiteManageProducts',
  components: { ScreenshotUpload },
  data() {
    return {
      newFaq: {
        question: '',
        answer: ''
      },
      selectedProductId: null,
      productChanges: {
        overrides: {}
      },
      currencies: {
        GBP: '£',
        USD: '$',
        EUR: '€'
      }
    };
  },
  computed: {
    ...mapGetters({
      products: 'websiteNew/products',
      loadingAction: 'websiteNew/loadingAction'
    }),
    groupedProducts() {
      return this.products.reduce((acc, product) => {
        const group = product.gid;
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(product);
        return acc;
      }, {});
    },
    selectedProduct() {
      const product = this.products.find(product => product._id === this.selectedProductId);
      if (!product.images) {
        product.images = {
          header: '',
          screenshots: []
        };
      }
      return product || {};
    },
    slotPricing() {
      const pricing = this.selectedProduct.slotPricing.reduce((acc, item) => {
        if (!acc.some(p => p.slots === item.slots)) {
          acc.push({
            slots: item.slots
          });
        }
        const slotItem = acc.find(p => p.slots === item.slots);
        slotItem[`${item.period}Amount${item.currencyCode}`] = item.amount;
        slotItem[`${item.period}PerSlot${item.currencyCode}`] = item.perSlot;
        return acc;
      }, []);
      pricing.sort((a, b) => a.slots - b.slots);
      return pricing;
    },
    fromPricing() {
      const pricing = this.selectedProduct.fromPricing.reduce((acc, item) => {
        if (!acc.some(p => p.slots === item.slots)) {
          acc.push({
            slots: item.slots
          });
        }
        const slotItem = acc.find(p => p.slots === item.slots);
        slotItem[`${item.period}Amount${item.currencyCode}`] = item.amount;
        slotItem[`${item.period}PerSlot${item.currencyCode}`] = item.perSlot;
        return acc;
      }, []);
      pricing.sort((a, b) => a.slots - b.slots);
      return pricing;
    }
  },
  mounted() {
    this.getProducts({ steam: true });

    if (this.$route.query.productId) {
      this.selectedProductId = this.$route.query.productId;
      this.productChanges.overrides = { ...(this.selectedProduct.overrides || {}) };
    }
  },
  methods: {
    ...mapActions({
      getProducts: 'websiteNew/getProducts',
      updateProduct: 'websiteNew/updateProduct'
    }),
    onChangeProduct() {
      this.productChanges.overrides = { ...(this.selectedProduct.overrides || {}) };
      this.$router.replace({ query: { productId: this.selectedProductId } });
    },
    onPropertyChange(field, value) {
      this.$set(this.productChanges, field, value);
    },
    onWHMCSOverride(field, value) {
      this.$set(this.productChanges.overrides, field, !value);
    },
    async onSubmitFAQ() {
      if (!this.selectedProduct.faq) this.selectedProduct.faq = [];
      this.selectedProduct.faq.push({ question: this.productChanges.newFAQQuestion, answer: this.productChanges.newFAQAnswer });
      await this.onPropertyChange('newFAQQuestion', '');
      await this.onPropertyChange('newFAQAnswer', '');

      await this.onPropertyChange('faq', this.selectedProduct.faq);

      this.$buefy.toast.open({
        message: 'FAQ added!',
        type: 'is-success'
      });

      this.newFaq = {
        question: '',
        answer: ''
      };
    },
    async onSubmitChanges() {
      if (Object.keys(this.productChanges).length === 0) return;
      await this.updateProduct({
        pid: this.selectedProduct.pid,
        data: this.productChanges
      });
      this.$buefy.toast.open({
        message: 'Product updated!',
        type: 'is-success'
      });
      this.productChanges = {};
      await this.getProducts();
    },
    async onUploadImage(type, upload) {
      const updatedImages = {
        header: this.selectedProduct.images.header,
        background: this.selectedProduct.images.background,
        logo: this.selectedProduct.images.logo,
        developerLogo: this.selectedProduct.images.developerLogo,
        screenshots: this.selectedProduct.images.screenshots || []
      };

      if (updatedImages.developerLogo instanceof Object) {
        updatedImages.developerLogo = '';
      }
      if (updatedImages.logo instanceof Object) {
        updatedImages.logo = '';
      }
      if (type !== 'screenshot') {
        updatedImages[type] = upload.url;
      } else {
        updatedImages.screenshots = [
          ...updatedImages.screenshots,
          {
            full: upload.url,
            thumb: upload.thumbUrl
          }
        ];
      }

      await this.updateProduct({
        pid: this.selectedProduct.pid,
        data: {
          images: updatedImages
        }
      });

      await new Promise(resolve => setTimeout(resolve, 3000));

      this.getProducts();
    },
    async onRemoveFaq(index) {
      const faq = this.selectedProduct.faq;

      faq.splice(index, 1);

      await this.updateProduct({
        pid: this.selectedProduct.pid,
        data: {
          faq
        }
      });

      this.getProducts();
    },
    async onRemoveImageLogo(type) {
      const images = this.selectedProduct.images;

      images[type] = '';

      await this.updateProduct({
        pid: this.selectedProduct.pid,
        data: {
          images
        }
      });

      this.getProducts();
    },
    async onRemoveImage(index) {
      const images = this.selectedProduct.images;

      images.screenshots.splice(index, 1);

      await this.updateProduct({
        pid: this.selectedProduct.pid,
        data: {
          images
        }
      });

      this.getProducts();
    }
  }
};
</script>
