import Vue from 'vue';

export default {
  getGames: async ({ commit }, { gid = 1, data }) => {
    commit('GET_WEBSITE_GAMES_REQUEST', { gid });
    try {
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/website/1/product?gid=${gid}`, data);

      commit('GET_WEBSITE_GAMES_SUCCESS', { gid, body });

      return body;
    } catch (e) {
      commit('GET_WEBSITE_GAMES_ERROR', { gid, error: e });
      return null;
    }
  },
  createGame: async ({ commit }, { data, preview = false }) => {
    commit('CREATE_WEBSITE_GAME_REQUEST', { preview });

    try {
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/website/1/product${preview ? '?preview=true' : ''}`, data);

      commit('CREATE_WEBSITE_GAME_SUCCESS', { body, preview });

      return body;
    } catch (e) {
      commit('CREATE_WEBSITE_GAME_ERROR', { error: e.body, preview });
      return null;
    }
  },
  deploy: async ({ commit }) => {
    commit('DEPLOY_WEBSITE_REQUEST');

    try {
      const { body } = await Vue.http.post(`${process.env.VUE_APP_API_URL}/website/5e4c62121e592d56e49954f3/deploy`);

      commit('DEPLOY_WEBSITE_SUCCESS', { body });

      return body;
    } catch (e) {
      commit('DEPLOY_WEBSITE_ERROR', { error: e.body });
      return null;
    }
  },
  deployStatus: async ({ commit }) => {
    commit('DEPLOY_STATUS_REQUEST');

    try {
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/website/5e4c62121e592d56e49954f3/deploy`);

      commit('DEPLOY_STATUS_SUCCESS', { body });

      return body;
    } catch (e) {
      commit('DEPLOY_STATUS_ERROR', { error: e.body });
      return null;
    }
  }
};
