import Backups from './Backups';

export const routes = [
  {
    path: '/backups',
    name: 'Backups',
    component: Backups,
    meta: {
      title: 'Backups'
    }
  }
];

export default routes;
