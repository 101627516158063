<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Create/edit backup rules</p>
    </header>

    <section class="modal-card-body">
      <b-field label="Name">
        <b-input v-model="form.gameId" placeholder="Enter a name" type="text" :disabled="isEdit == true" />
      </b-field>

      <b-field label="Includes">
        <b-taginput v-model="form.includes" ellipsis icon="label" placeholder="Add a folder" aria-close-label="Delete this tag"> </b-taginput>
      </b-field>

      <b-field label="Excludes">
        <b-taginput v-model="form.excludes" ellipsis icon="label" placeholder="Add a folder" aria-close-label="Delete this tag"> </b-taginput>
      </b-field>
    </section>

    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Close</button>

      <button class="button is-primary" @click.prevent="onClickSubmit">Submit</button>
    </footer>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: ['item', 'isEdit'],
  data() {
    return {
      form: {}
    };
  },
  mounted() {
    this.form = { ...this.$props.item };
  },
  methods: {
    ...mapActions({
      updateBackupRule: 'backup/updateBackupRule'
    }),
    async onClickSubmit() {
      if (!this.form.excludes) {
        this.form.excludes = [''];
      }
      if (!this.form.includes) {
        this.form.includes = [''];
      }
      this.form.user = this.$auth.user.name;
      const data = await this.updateBackupRule(this.form);
      if (data == 'Updated') {
        this.$emit('submit');
        this.$parent.close();
      }
    }
  }
};
</script>
