<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Add/Edit Game</p>
    </header>
    <section class="modal-card-body">
      <div v-if="!deployed && !deploying && !error" class="content">
        <p>You are about to re-deploy the website on Netlify, are you sure you would like to proceed?</p>
      </div>
      <div class="content">
        <b-button
          v-if="!deployed && !deploying && !error"
          class="button is-primary is-fullwidth"
          :loading="loadingAction.deploy"
          @click.prevent="onClickSubmit"
        >
          Yes deploy!
        </b-button>
        <b-progress
          v-else-if="!deployed && deploying && !error"
          :value="progressValue[deployStatus.state]"
          :show-value="!!progressValue[deployStatus.state]"
          size="is-large"
        >
          {{ deployStatus.state }}...
        </b-progress>
        <p v-else-if="deployed && !deploying && !error">Deploy completed! You may now close this window.</p>
        <b-message v-else type="is-danger"> <strong>Deploy failed</strong><br />Reason: {{ error }} </b-message>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Close</button>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {},
  data() {
    return {
      poll: null,
      deploying: false,
      deployed: false,
      error: null,
      progressValue: {
        building: 20,
        uploading: 70,
        processing: 80,
        ready: 100
      }
    };
  },
  computed: {
    ...mapGetters({
      loadingAction: 'website/loadingAction',
      deployStatus: 'website/deployStatus'
    })
  },
  mounted() {
    this.clearDeployStatus();
  },
  beforeDestroy() {
    clearInterval(this.poll);
  },
  methods: {
    ...mapActions({
      deployWebsite: 'website/deploy',
      getDeployStatus: 'website/deployStatus'
    }),
    ...mapMutations({
      clearDeployStatus: 'website/DEPLOY_STATUS_CLEAR'
    }),
    async onClickSubmit() {
      await this.deployWebsite();
      await this.pollDeployStatus();
    },
    async pollDeployStatus() {
      this.deploying = true;

      this.poll = setInterval(async () => {
        const deploy = await this.getDeployStatus();
        console.log(deploy);

        if (deploy.state === 'ready' || deploy.state === 'error') {
          clearInterval(this.poll);
          this.deploying = false;
          this.deployed = deploy.state !== 'error';
          this.error = deploy.error_message;
        }
      }, 5000);
    }
  }
};
</script>

<style></style>
