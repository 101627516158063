<template>
  <div class="container">
    <section>
      <h1>Mods for Games</h1>
      <b-modal :active.sync="isEditModal" has-modal-card scroll="keep">
        <ModForm v-bind="editMod" @submit="refresh" />
      </b-modal>
      <b-tabs v-model="activeTab">
        <section class="section">
          <b-tab-item label="API"><API :apis="programmedAPIs" :mod="editMod" /> </b-tab-item>
          <b-tab-item label="Scraper">
            <Scraper :scrapers="programmedScrapers" />
          </b-tab-item>
          <b-tab-item label="TCAdmin">
            <div class="columns">
              <div class="column">
                <b-field label="Game">
                  <b-select v-model="selectedGame" placeholder="Select a game">
                    <option v-for="option in this.games" :key="option.display_name" :value="option.display_name">
                      {{ option.display_name.replace(/%20/g, ' ') }}
                    </option>
                  </b-select>
                  <b-button @click="getModsByGame(selectedGame)">Filter</b-button>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Global Actions">
                  <b-button :disabled="mods.length === 0" @click="updateAllMods(modsTable)">Update all mods</b-button>
                </b-field>
              </div>
            </div>

            <b-modal v-model="updateAllModsOutput">
              <div class="container">
                <div class="card">
                  <div class="card-content">
                    <div class="media-content">
                      <p class="title is-4">Updating Mods</p>
                    </div>
                  </div>

                  <div class="content">
                    <b-progress class="m-5" :value="progress" show-value format="percent"></b-progress>
                    <b-input id="outputText" v-model="updateAllModsOuputText" type="textarea" rows="35" />
                  </div>
                </div>
              </div>
            </b-modal>

            <b-table
              :data="modsTable"
              paginated
              :total="modsTotal"
              :per-page="20"
              hoverable
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              backend-sorting
            >
              <b-table-column v-slot="props" searchable width="200" field="display_name" label="Game" sortable>
                {{ props.row.display_name }}
              </b-table-column>
              <b-table-column v-slot="props" searchable width="160" field="group_name" label="Group Name" sortable>
                {{ props.row.group_name }}
              </b-table-column>
              <b-table-column v-slot="props" field="homepage_url" width="300" label="Homepage" sortable>
                <p class="small">{{ props.row.homepage_url }}</p>
              </b-table-column>
              <b-table-column v-slot="props" class="fixedColumn" width="300" field="win_filename" label="Download URL" sortable>
                <p class="small">{{ props.row.win_filename }}</p>
              </b-table-column>
              <b-table-column v-slot="props" width="165" label="Actions">
                <b-button type="is-info" size="is-small" :disabled="!props.row.fetchMethod" @click.prevent="() => onClickUpdate(props.row)"
                  >Update</b-button
                >
                <b-button disabled="true" size="is-small" @click.prevent="() => onClickModify(props.row)">Modify</b-button>
              </b-table-column>
            </b-table>
          </b-tab-item>
        </section>
      </b-tabs>
    </section>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ModForm from './ModForm';
import Scraper from './Scraper';
import API from './API';

export default {
  name: 'Mods',
  components: {
    ModForm,
    Scraper,
    API
  },
  data() {
    return {
      isEditModal: false,
      activeTab: 0,
      selectedGame: '',
      updateAllModsOuputText: '',
      updateAllModsOutput: false,
      progress: 0,
      modsTable: [],
      editMod: {},
      programmedAPIs: [{ game: 'valheim', name: 'thunderstore' }],
      programmedScrapers: [{ game: 'valheim', name: 'nexusmods' }]
    };
  },
  computed: {
    ...mapGetters({
      mods: 'mod/mods',
      games: 'mod/games'
    }),
    modsTotal() {
      return this.mods.length;
    }
  },
  created() {
    this.getGames();
  },
  methods: {
    ...mapActions({
      getGames: 'mod/getGames',
      getMods: 'mod/getMods',
      scrapeMod: 'mod/scrapeMod',
      callAPI: 'mod/callAPI',
      uploadModToTCAdmin: 'mod/uploadMod'
    }),
    async refresh() {
      if (this.games.length > 0) {
        this.getMods();
      }
    },
    async getModsByGame(game) {
      this.modsTable = await this.getMods({ gameName: game });

      for (let t = 0; t < this.modsTable.length; t++) {
        for (let i = 0; i < this.programmedAPIs.length; i++) {
          if (this.modsTable[t].group_name.toLowerCase().includes(this.programmedAPIs[i].name)) {
            this.modsTable[t].fetchMethod = 'api';
            this.modsTable[t].game = game.toLowerCase();
            this.modsTable[t].api = this.programmedAPIs[i].name;
          }
        }
        for (let i = 0; i < this.programmedScrapers.length; i++) {
          if (this.modsTable[t].group_name.toLowerCase().includes(this.programmedScrapers[i]).name) {
            this.modsTable[t].fetchMethod = 'scraper';
            this.modsTable[t].game = game.toLowerCase();
            this.modsTable[t].scraper = this.programmedScrapers[i].name;
          }
        }
      }
    },
    async onClickModify(mod) {
      this.editMod = mod;
      this.isEditModal = true;
    },
    async updateAllMods(table) {
      this.updateAllModsOutput = true;
      for (var i = 0; i < table.length; i++) {
        console.log(table[i]);
        if (table[i].fetchMethod) {
          if (table[i].api) {
            const newData = await this.callAPI({ game: table[i].game, api: table[i][table[i].fetchMethod], URL: table[i].homepage_url });
            const response = await this.uploadModToTCAdmin({ game: table[i].game, APIMod: newData });
            this.updateAllModsOuputText = this.updateAllModsOuputText + '\n' + table[i].display_name + ' - ' + response;
            await this.timeout(1000);
          }
        }
        this.progress = (i / 100) * 100;
      }
      this.progress = 100;
    },
    async timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async onClickUpdate(mod) {
      const newData = await this.callAPI({ game: mod.game, api: mod[mod.fetchMethod], URL: mod.homepage_url });
      const response = await this.uploadModToTCAdmin({ game: mod.game, APIMod: newData });

      if (response === 'Uploaded to TCAdmin') {
        this.$buefy.toast.open({
          duration: 10000,
          container: 'result',
          message: 'Mod uploaded',
          position: 'is-bottom',
          type: 'is-success'
        });
      } else {
        this.$buefy.toast.open({
          duration: 10000,
          container: 'result',
          message: 'Error uploading mod',
          position: 'is-bottom',
          type: 'is-danger'
        });
      }
    }
  }
};
</script>

<style>
.divider {
  height: 1px;
  margin: 0 auto;
  background: blue;
  margin-top: 3rem !important;
  margin-bottom: 2rem !important;
  background: linear-gradient(90deg, hsl(0, 0%, 70%), hsl(0, 0%, 20%), hsl(0, 0%, 70%));
}
td {
  word-break: break-all;
}

.small {
  font-size: 12px;
}
</style>
