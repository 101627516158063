import actions from './actions';
import mutations from './mutations';
import crudActions from '../../lib/crudActions';

const store = {
  namespaced: true,
  state: {
    loading: false,
    error: '',
    node: {},
    nodes: [],
    total: 0,
    loadingAction: {
      ...crudActions,
      checkDns: false,
      provisionNode: false,
      monitorSetup: false,
      monitorConfig: false
    },
    checkDnsResponse: {},
    checkDnsError: {},
    monitoring: {
      nodes: []
    }
  },
  getters: {
    loading: state => state.loading,
    error: state => state.error,
    nodes: state => state.nodes,
    node: state => state.node,
    total: state => state.total,
    loadingAction: state => state.loadingAction,
    checkDnsResponse: state => state.checkDnsResponse,
    checkDnsError: state => state.checkDnsError,
    monitoring: state => state.monitoring
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
