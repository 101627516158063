import Mods from './Mods';

export const routes = [
  {
    path: '/mods',
    name: 'Mods',
    component: Mods,
    meta: {
      title: 'Mods'
    }
  }
];

export default routes;
