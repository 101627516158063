<template>
  <div>
    <main id="main-container">
      <Navbar />
      <router-view />
    </main>
    <footer id="page-footer" />
  </div>
</template>

<script>
import Navbar from '../components/Navbar';

export default {
  name: 'Main',
  components: {
    Navbar
  }
};
</script>
