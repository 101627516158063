import crudActions from '../lib/crudActions';

export default (singular, plural) => {
  const mutations = {};

  Object.keys(crudActions).forEach(crudAction => {
    mutations[`${crudAction.toUpperCase()}_${singular.toUpperCase()}_REQUEST`] = state => {
      state.loading = true;
      if (state.loadingAction) state.loadingAction[crudAction] = true;
    };
    mutations[`${crudAction.toUpperCase()}_${singular.toUpperCase()}_SUCCESS`] = (state, body) => {
      state.loading = false;
      if (state.loadingAction) state.loadingAction[crudAction] = false;
      state.error = '';

      if (body) {
        if (crudAction === 'list') {
          state[plural] = body.data;
          state.limit = body.limit;
          state.total = body.total;
          state.skip = body.skip;
        } else {
          state[singular] = body;
        }
      }
    };
    mutations[`${crudAction.toUpperCase()}_${singular.toUpperCase()}_ERROR`] = (state, error) => {
      state.loading = false;
      if (state.loadingAction) state.loadingAction[crudAction] = false;
      state.error = error;
    };
  });

  mutations[`CLEAR_${singular.toUpperCase()}`] = state => {
    state.loading = false;
    state[singular] = {};
    state.error = '';
  };

  return mutations;
};
