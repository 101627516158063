<template>
  <div>
    <div class="block">
      <h4 class="title is-4">Manage Product Options</h4>
      <p class="subtitle is-5">Options that show on the order form, and/or used for the control panel.</p>

      <div class="columns is-centered">
        <div class="column is-one-third">
          <b-button type="is-primary is-fullwidth" @click="onToggleProductOptionGroupForm"><i class="fa fa-plus mr-1"></i>Add Group</b-button>
        </div>
      </div>
    </div>
    <!-- 
    <div v-if="forms.productOptionGroupForm.show" class="p-5 has-background-light mb-4">
      <div class="mb-5">
        <p class="title is-4">Add Product Option Group</p>
        <ProductOptionGroupForm v-model="forms.productOptionGroupForm.data" />
        <b-button
          class="mb-4"
          type="is-primary is-fullwidth"
          :loading="loadingAction.createProductOption"
          :disabled="loadingAction.createProductOption"
          @click="onClickSubmitProductOptionGroup"
          >Submit</b-button
        >
      </div>
    </div> -->

    <b-modal v-model="forms.productOptionGroupForm.show" trap-focus :destroy-on-hide="false">
      <div class="card">
        <div class="card-content">
          <p class="title is-4">
            {{ forms.productOptionGroupForm.isEdit ? `Edit: ${forms.productOptionGroupForm.data.name}` : 'Add Product Option Group' }}
          </p>
          <b-message v-if="errorAction.createProductOption" type="is-danger">{{ errorAction.createProductOption }}</b-message>
          <ProductOptionGroupForm v-model="forms.productOptionGroupForm.data" />
          <div class="has-text-right">
            <b-button
              class="mt-4"
              type="is-primary"
              :loading="loadingAction.createProductOption || loadingAction.updateProductOption"
              :disabled="loadingAction.createProductOption || loadingAction.updateProductOption"
              @click="onClickSubmitProductOptionGroup"
              >Save</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>

    <div v-if="forms.productOptionForm.show" class="p-5 has-background-light mb-4">
      <div class="mb-5">
        <p class="title is-4">Add Product Option</p>
        <b-message v-if="errorAction.updateProductOption" type="is-danger">{{ errorAction.updateProductOption }}</b-message>
        <ProductOptionForm v-model="forms.productOptionForm.data" />
        <b-button
          class="mb-4"
          type="is-primary is-fullwidth"
          :loading="loadingAction.updateProductOption"
          :disabled="loadingAction.updateProductOption"
          @click="onClickSubmitProductOption"
          >Submit</b-button
        >
      </div>
    </div>

    <div v-for="group in productOptions" :key="group._id" class="box">
      <div class="media mb-4">
        <div class="media-content">
          <p class="title is-4 mb-1">{{ group.name }}</p>
          <p class="mb-2">{{ group.description }}</p>
        </div>
        <div class="media-right has-text-right">
          <b-button class="mr-3" type="is-primary" @click="onToggleProductOptionForm(group)"><i class="fa fa-plus mr-1"></i>Add Option</b-button>
          <b-button type="is-info mr-3" @click="onToggleProductOptionGroupForm(group)"><i class="fa fa-pencil mr-1"></i> Edit</b-button>
          <b-button type="is-danger" @click="onClickDeleteProductOptionForm(group)"><i class="fa fa-trash mr-1"></i> Delete</b-button>
        </div>
      </div>

      <b-message v-if="group.options.length === 0" type="is-warning">This product group has no options. </b-message>
      <div v-for="option in group.options" :key="option._id" class="media mb-3">
        <div class="media-content">
          <p class="title is-5 mb-1">{{ option.name }}</p>
          <p class="mb-2">{{ option.description }}</p>
        </div>
        <div class="media-right has-text-right">
          <b-button type="is-ghost" @click="onToggleProductOptionForm(group, option)"><i class="fa-light fa-pencil"></i> Edit</b-button>
          <b-button type="is-ghost has-text-danger" @click="onClickDeleteProductOption(group, option)"
            ><i class="fa-light fa-trash"></i> Delete</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import ProductOptionForm from '@/components/forms/ProductOptionForm';
import ProductOptionGroupForm from '@/components/forms/ProductOptionGroupForm';

export default {
  components: {
    ProductOptionForm,
    ProductOptionGroupForm
  },
  data() {
    return {
      selectedOptionId: null,
      optionChanges: {
        overrides: {}
      },
      currencies: {
        GBP: '£',
        USD: '$',
        EUR: '€'
      },
      forms: {
        productOptionGroupForm: {
          show: false,
          isEdit: false,
          data: {}
        },
        productOptionForm: {
          parentId: null,
          show: false,
          isEdit: false,
          data: {}
        }
      },
      error: ''
    };
  },
  computed: {
    ...mapGetters({
      productOptions: 'websiteNew/productOptions',
      loadingAction: 'websiteNew/loadingAction',
      errorAction: 'websiteNew/errorAction'
    }),
    groupedOptions() {
      return this.productOptions.reduce((acc, product) => {
        const group = product.category;
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(product);
        return acc;
      }, {});
    },
    selectedOption() {
      return this.productOptions.find(product => product._id === this.selectedOptionId);
    }
  },
  mounted() {
    this.refresh();

    if (this.$route.query.optionId) {
      this.selectedOptionId = this.$route.query.optionId;
      this.optionChanges.overrides = { ...(this.selectedOption.overrides || {}) };
    }
  },
  methods: {
    ...mapActions({
      getProducts: 'websiteNew/getProducts',
      getProductOptions: 'websiteNew/getProductOptions',
      updateProductOption: 'websiteNew/updateProductOption',
      createProductOption: 'websiteNew/createProductOption',
      deleteProductOption: 'websiteNew/deleteProductOption'
    }),
    async refresh() {
      this.getProductOptions();
      this.getProducts();
    },
    async onSubmitChanges() {
      if (Object.keys(this.optionChanges).length === 0) return;

      await this.updateProductOption({
        pid: this.selectedOption.pid,
        data: this.optionChanges
      });

      this.$buefy.toast.open({
        message: 'Product updated!',
        type: 'is-success'
      });

      this.optionChanges = {};

      this.refresh();
    },
    async onClickSubmitProductOptionGroup() {
      if (this.forms.productOptionGroupForm.isEdit) {
        await this.updateProductOption({
          data: this.forms.productOptionGroupForm.data
        });
      } else {
        await this.createProductOption({
          data: {
            ...this.forms.productOptionGroupForm.data,
            options: []
          }
        });
      }

      if (this.errorAction.createProductOption || this.errorAction.updateProductOption) {
        this.$buefy.toast.open({
          message: 'Product group could not be created/updated.',
          type: 'is-danger'
        });
        return;
      }

      this.$buefy.toast.open({
        message: 'Product group created/updated.',
        type: 'is-success'
      });

      this.forms.productOptionGroupForm.show = false;

      this.refresh();
    },
    async onClickSubmitProductOption() {
      // Refresh product option groups
      await this.getProductOptions();

      const productOptionGroup = this.productOptions.find(productOption => productOption._id === this.forms.productOptionForm.parentId);

      await this.updateProductOption({
        params: {
          id: this.forms.productOptionForm.parentId
        },
        data: {
          options: [...productOptionGroup.options.filter(o => o._id !== this.forms.productOptionForm.data._id), this.forms.productOptionForm.data]
        }
      });

      if (this.errorAction.updateProductOption) {
        this.$buefy.toast.open({
          message: 'Product option could not be added.',
          type: 'is-danger'
        });

        this.error = this.errorAction.updateProductOption;

        return;
      }

      this.$buefy.toast.open({
        message: 'Product group added.',
        type: 'is-success'
      });

      this.forms.productOptionForm.show = false;

      this.refresh();
    },
    async onClickDeleteProductOption(group, option) {
      try {
        // Refresh product option groups
        await this.getProductOptions();

        const productOptionGroup = this.productOptions.find(productOption => productOption._id === group._id);

        await this.updateProductOption({
          params: {
            id: group._id
          },
          data: {
            options: productOptionGroup.options.filter(productOption => productOption._id !== option._id)
          }
        });

        this.$buefy.toast.open({
          message: 'Product option deleted.',
          type: 'is-success'
        });
      } catch (e) {
        this.$buefy.toast.open({
          message: 'Product option could not be deleted.',
          type: 'is-error'
        });
      }

      this.forms.productOptionForm.show = false;

      this.refresh();
    },
    async onClickDeleteProductOptionForm(group) {
      // Refresh product option groups
      await this.deleteProductOption({
        params: {
          id: group._id
        }
      });

      if (this.errorAction.deleteProductOption) {
        this.$buefy.toast.open({
          message: 'Product group could not be deleted.',
          type: 'is-danger'
        });

        this.error = this.errorAction.deleteProductOption;

        return;
      }

      this.$buefy.toast.open({
        message: 'Product group deleted.',
        type: 'is-success'
      });

      this.refresh();
    },
    onToggleProductOptionGroupForm(group) {
      this.forms.productOptionGroupForm.show = !this.forms.productOptionGroupForm.show;

      if (group) {
        this.forms.productOptionGroupForm.isEdit = true;
        this.forms.productOptionGroupForm.data = group;
      } else {
        this.forms.productOptionGroupForm.isEdit = false;
        this.forms.productOptionGroupForm.data = {};
      }
    },
    onToggleProductOptionForm(group, option) {
      this.forms.productOptionForm.show = !this.forms.productOptionForm.show;

      if (group) {
        this.forms.productOptionForm.parentId = group._id;
      }

      if (option) {
        this.forms.productOptionForm.isEdit = true;
        this.forms.productOptionForm.data = option;
      } else {
        this.forms.productOptionForm.isEdit = false;
        this.forms.productOptionForm.data = {};
      }
    }
  }
};
</script>
