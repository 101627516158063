import actions from './actions';
import mutations from './mutations';

const store = {
  namespaced: true,
  state: {
    products: [],
    scrapers: [],
    loadingAction: {
      ...Object.keys(actions).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    },
    errorAction: {
      ...Object.keys(actions).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    },
    progressAction: {
      uploadProductImage: 0
    },
    blogs: [],
    knowledgebases: [],
    promos: [],
    productOptions: []
  },
  getters: {
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,
    progressAction: state => state.progressAction,
    products: state => state.products,
    scrapers: state => state.scrapers,
    nitradoPrices: state => state.nitradoPrices,
    gportalPrices: state => state.gportalPrices,
    blogs: state => state.blogs,
    knowledgebases: state => state.knowledgebases,
    promos: state => state.promos,
    productOptions: state => state.productOptions
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
