<template>
  <div v-if="$auth.isAuthenticated && $auth.user.email" id="app">
    <router-view />
  </div>
  <div v-else id="page-loader" class="bg-primary show"></div>
</template>

<script>
export default {
  name: 'App',
  head: {
    script: [
      {
        type: 'text/javascript',
        src: 'https://kit.fontawesome.com/896f231cf4.js',
        crossorigin: 'anonymous'
      }
    ]
  }
};
</script>
