import Vue from 'vue';
import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory('node', 'nodes', 'node'),
  checkDns: async ({ commit }, { id }) => {
    commit('CHECK_DNS_REQUEST', { id });
    try {
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/node/${id}/dns`);

      commit('CHECK_DNS_SUCCESS', { id, body });

      return body;
    } catch (e) {
      commit('CHECK_DNS_ERROR', { id, error: e });
      return null;
    }
  },
  provision: async ({ commit }, { id }) => {
    commit('PROVISION_NODE_REQUEST', { id });
    try {
      const { body } = await Vue.http.put(`${process.env.VUE_APP_API_URL}/node/${id}/provision`);

      commit('PROVISION_NODE_SUCCESS', { id, body });

      return body;
    } catch (e) {
      commit('PROVISION_NODE_ERROR', { id, error: e });
      return null;
    }
  },
  monitorSetup: async ({ commit }, { id }) => {
    commit('MONITOR_SETUP_REQUEST', { id });
    try {
      const { body } = await Vue.http.put(`${process.env.VUE_APP_API_URL}/node/${id}/monitor-setup`);

      commit('MONITOR_SETUP_SUCCESS', { id, body });

      return body;
    } catch (e) {
      commit('MONITOR_SETUP_ERROR', { id, error: e });
      return null;
    }
  },
  monitorConfig: async ({ commit }, { id }) => {
    commit('MONITOR_CONFIG_REQUEST', { id });
    try {
      const { body } = await Vue.http.put(`${process.env.VUE_APP_API_URL}/node/${id}/monitor-config`);

      commit('MONITOR_CONFIG_SUCCESS', { id, body });

      return body;
    } catch (e) {
      commit('MONITOR_CONFIG_ERROR', { id, error: e });
      return null;
    }
  }
};
