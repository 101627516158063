import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory('mod', 'mods'),
  SCRAPE_MOD_DATA_REQUEST: state => {
    state.loadingAction = true;
  },
  SCRAPE_MOD_DATA_SUCCESS: (state, body) => {
    state.scrapedMod = body.body;
    state.loadingAction = false;
  },
  SCRAPE_MOD_DATA_ERROR: state => {
    state.loadingAction = false;
  },
  UPLOAD_MOD_DATA_REQUEST: state => {
    state.loadingAction = true;
  },
  UPLOAD_MOD_DATA_SUCCESS: state => {
    state.loadingAction = false;
  },
  UPLOAD_MOD_DATA_ERROR: state => {
    state.loadingAction = false;
  },
  GET_MODS_DATA_REQUEST: state => {
    state.loadingAction = true;
  },
  GET_MODS_DATA_SUCCESS: (state, body) => {
    state.mods = body.body;
    state.loadingAction = false;
  },
  GET_MODS_DATA_ERROR: state => {
    state.loadingAction = false;
  },
  GET_GAMES_DATA_REQUEST: state => {
    state.loadingAction = true;
  },
  GET_GAMES_DATA_SUCCESS: (state, body) => {
    state.games = body.body;
    state.loadingAction = false;
  },
  GET_GAMES_DATA_ERROR: state => {
    state.loadingAction = false;
  }
};
