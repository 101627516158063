import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory('backup', 'backups'),
  GET_BACKUP_RULES_REQUEST: state =>
    (state.loadingAction.getBackupRules = {
      progress: 0
    }),
  GET_BACKUP_RULES_SUCCESS: (state, body) => {
    state.backupRules = body.body;
    state.loadingAction.getBackupRules = null;
  },
  GET_BACKUP_RULES_ERROR: state => {
    state.loadingAction.getBackupRules = null;
  },
  GET_BACKUP_RULES_PROGRESS: (state, { progress }) => {
    state.loadingAction.getBackupRules = {
      progress
    };
  },
  UPDATE_BACKUP_RULES_REQUEST: state =>
    (state.loadingAction.updateBackupRules = {
      progress: 0
    }),
  UPDATE_BACKUP_RULES_SUCCESS: state => {
    state.loadingAction.updateBackupRules = null;
  },
  UPDATE_BACKUP_RULES_ERROR: state => {
    state.loadingAction.updateBackupRules = null;
  },
  UPDATE_BACKUP_RULES_PROGRESS: (state, { progress }) => {
    state.loadingAction.updateBackupRules = {
      progress
    };
  }
};
