import Vue from 'vue';
import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory('backup', 'backups', 'backup'),
  getBackupRules: async ({ commit }, params) => {
    commit('GET_BACKUP_RULES_REQUEST', { params });
    try {
      const headers = { 'x-api-key': 'pQCInSsD1r8SpNEV8M4wX22lxbBl0XyX4YCm04Kw' };

      const backup = await Vue.http.get(`https://6bdw94vul5.execute-api.eu-west-1.amazonaws.com/dev`, { headers });

      backup.body.Items.total = backup.body.ScannedCount;

      const sortedRules = backup.body.Items;

      sortedRules.sort((a, b) => (a.gameId > b.gameId ? 1 : -1));

      commit('GET_BACKUP_RULES_SUCCESS', { params, body: sortedRules });

      return backup;
    } catch (e) {
      commit('GET_BACKUP_RULES_ERROR', { params, error: e });
      return null;
    }
  },
  updateBackupRule: async ({ commit }, params) => {
    commit('UPDATE_BACKUP_RULES_REQUEST', { params });
    try {
      const headers = { 'x-api-key': 'pQCInSsD1r8SpNEV8M4wX22lxbBl0XyX4YCm04Kw' };
      await Vue.http.post(
        `https://6bdw94vul5.execute-api.eu-west-1.amazonaws.com/dev/update/` + params.gameId,
        {
          includes: params.includes,
          excludes: params.excludes,
          user: params.user
        },
        { headers }
      );
      commit('UPDATE_BACKUP_RULES_SUCCESS', { params });

      return 'Updated';
    } catch (e) {
      commit('UPDATE_BACKUP_RULES_ERROR', { params, error: e });
      return e;
    }
  }
};
