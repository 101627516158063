<template>
  <div class="block">
    <h4 class="title is-4">Update Products</h4>
    <p class="subtitle">This will update the pricing of all products (from WHMCS), and update any Steam data and images against products.</p>
    <b-button label="Sync" :loading="loadingAction.syncProducts" @click="syncProducts" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'WebsiteSyncProducts',
  computed: {
    ...mapGetters({
      products: 'websiteNew/products',
      loadingAction: 'websiteNew/loadingAction'
    })
  },
  methods: {
    ...mapActions({
      syncProducts: 'websiteNew/syncProducts'
    })
  }
};
</script>
