<template>
  <div class="container">
    <section>
      <h1>Backup Rules for Games</h1>
      <b-button @click.prevent="() => onClickCreate()">Create</b-button>
      <b-modal :active.sync="isEditModal" has-modal-card scroll="keep">
        <BackupForm v-bind="editProps" @submit="refresh" />
      </b-modal>
      <b-table
        :data="backupRules"
        paginated
        :total="backupRules.total"
        :per-page="10"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        backend-sorting
      >
        <b-table-column v-slot="props" field="gameId" label="Game" sortable>
          {{ props.row.gameId.replace(/%20/g, ' ') }}
        </b-table-column>
        <b-table-column v-slot="props" field="includes" label="Included Folders / Files" sortable>
          {{ props.row.includes }}
        </b-table-column>
        <b-table-column v-slot="props" field="excludes" label="Excluded Folders / Files" sortable>
          {{ props.row.excludes }}
        </b-table-column>
        <b-table-column v-slot="props" width="190" label="Actions">
          <b-button @click.prevent="() => onClickModify(props.row)">Modify</b-button>
          <b-button v-if="$auth.isAdmin" @click.prevent="() => onClickDelete(props.row)">Delete</b-button>
        </b-table-column>
      </b-table>
    </section>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BackupForm from './BackupForm';

export default {
  name: 'Backups',
  components: {
    BackupForm
  },
  data() {
    return {
      isEditModal: false,
      editProps: {}
    };
  },
  computed: {
    ...mapGetters({
      backupRules: 'backup/backupRules'
    })
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions({
      getBackupsRules: 'backup/getBackupRules'
    }),
    async refresh() {
      this.getBackupsRules();
    },
    async onClickModify(item) {
      this.editProps = {
        item,
        isEdit: true
      };
      this.isEditModal = true;
    },
    async onClickCreate() {
      this.editProps = {
        isEdit: false
      };
      this.isEditModal = true;
    },
    async onClickDelete(item) {
      console.log('Delete function is currently uncoded');
      console.info(item);
    }
  }
};
</script>
