import actions from './actions';
import mutations from './mutations';
import crudActions from '../../lib/crudActions';

const store = {
  namespaced: true,
  state: {
    loading: false,
    error: '',
    loadingAction: {
      ...crudActions,
      nodeSummary: false,
      updateNodeData: false
    },
    nodeSummary: {
      results: [],
      error: null
    },
    updateNodeDataResponse: []
  },
  getters: {
    loading: state => state.loading,
    error: state => state.error,
    loadingAction: state => state.loadingAction,
    nodeSummary: state => state.nodeSummary,
    updateNodeDataResponse: state => state.updateNodeDataResponse
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
