import Vue from 'vue';

const crudActions = {
  list: 'get',
  get: 'get',
  create: 'post',
  update: 'patch',
  remove: 'delete'
};

export default (singular, plural, resourceUrl = '') => {
  const actions = {};

  let apiUrl = resourceUrl;

  if (!resourceUrl) {
    apiUrl = plural;
  }

  Object.keys(crudActions).forEach(crudAction => {
    const func = async ({ commit }, { id, data } = {}) => {
      const method = crudActions[crudAction];

      let url = `${process.env.VUE_APP_API_URL}/${apiUrl}`;
      if (id) url = `${url}/${id}`;

      commit(`${crudAction.toUpperCase()}_${singular.toUpperCase()}_REQUEST`);

      try {
        const { body } = await Vue.http[method](url, data);

        commit(`${crudAction.toUpperCase()}_${singular.toUpperCase()}_SUCCESS`, crudAction === 'list' || crudAction === 'get' ? body : undefined);
        return body;
      } catch (e) {
        commit(`${crudAction.toUpperCase()}_${singular.toUpperCase()}_ERROR`, e);
        return null;
      }
    };

    actions[crudAction] = func;
  });

  actions.clear = ({ commit } = {}) => {
    commit(`CLEAR_${singular.toUpperCase()}`);
  };

  return actions;
};
