<template>
  <div class="block">
    <h4 class="title is-4">Blog Articles</h4>
    <p class="subtitle is-5">See all blog articles for LOW.MS.</p>
    <b-table :data="blogs">
      <b-table-column v-slot="props" label="Title" field="title">
        <router-link title="asd" :to="{ name: 'website-blogs-edit', params: { id: props.row._id } }">{{ props.row.title }}</router-link>
      </b-table-column>
      <b-table-column v-slot="props" label="Created At" field="createdAt">
        {{ props.row.createdAt }}
      </b-table-column>
      <b-table-column v-slot="props" label="Published" field="published">
        {{ props.row.published }}
      </b-table-column>
    </b-table>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'WebsiteBlogList',
  props: {
    subMenu: {
      type: String,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      blogs: 'websiteNew/blogs',
      loadingAction: 'websiteNew/loadingAction'
    })
  },
  mounted() {
    this.getBlogs({});
  },
  methods: {
    ...mapActions({
      getBlogs: 'websiteNew/getBlogs'
    })
  }
};
</script>
