import actions from './actions';
import mutations from './mutations';
import crudActions from '../../lib/crudActions';

const store = {
  namespaced: true,
  state: {
    loading: false,
    error: '',
    total: 0,
    backups: [],
    backup: {},
    backupRules: {},
    loadingAction: {
      ...crudActions,
      getDownload: false
    }
  },
  getters: {
    backups: state => state.backups,
    backup: state => state.backup,
    backupRules: state => state.backupRules,
    total: state => state.total,
    loading: state => state.loading,
    error: state => state.error,
    loadingAction: state => state.loadingAction
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
