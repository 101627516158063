import Vue from 'vue';
import Vuex from 'vuex';

import nodeStore from './node';
import financeStore from './finance';
import backupStore from './backup';
import modStore from './mod';
import websiteStore from './website';
import websiteNewStore from './websiteNew';

Vue.use(Vuex);

const store = {
  modules: {
    node: nodeStore,
    finance: financeStore,
    backup: backupStore,
    mod: modStore,
    website: websiteStore,
    websiteNew: websiteNewStore
  },
  state: {},
  getters: {},
  mutations: {},
  actions: {}
};

export default new Vuex.Store(store);
