<template>
  <section>
    <h2>Unknown if working, for Valheim simply use the API</h2>
    <div class="columns">
      <div class="column">
        <b-field label="Game">
          <b-select v-model="selectedScraperGame" placeholder="Select a game">
            <option v-for="item in scrapers" :key="item.game" :value="item.game">
              {{ item.game }}
            </option>
          </b-select>
        </b-field>
      </div>

      <div class="column">
        <b-field label="Scraper">
          <b-select v-model="selectedScraper" :disabled="selectedScraperGame.length === 0">
            <option v-for="scrape in scrapers" :key="scrape.name" :value="scrape.name">
              {{ scrape.name }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <b-field label="URL">
      <b-input v-model="scrapeURL" placeholder="Enter a URL" type="text" />
    </b-field>
    <b-button @click="runScraper()">Start Scraper</b-button>
    <div class="divider" />
    <div>
      <b-field label="Mod Name">
        <b-input v-model="scrapedMod.modName" :disabled="!scraperCompleted" type="text" />
      </b-field>
      <b-field label="Group Name">
        <b-input v-model="scrapedMod.modGroup" :disabled="!scraperCompleted" type="text" />
      </b-field>
      <b-field label="Extract Path">
        <b-input v-model="scrapedMod.modExtractPath" :disabled="!scraperCompleted" type="text" />
      </b-field>
      <b-field label="Comments">
        <b-input v-model="scrapedMod.modComments" :disabled="!scraperCompleted" type="text" />
      </b-field>
      <b-field label="Help URL">
        <b-input v-model="scrapedMod.modHomepageURL" :disabled="!scraperCompleted" type="text" />
      </b-field>
      <b-field label="Homepage URL">
        <b-input v-model="scrapedMod.modHomepageURL" :disabled="!scraperCompleted" type="text" />
      </b-field>
      <b-field label="Download URL">
        <b-input v-model="scrapedMod.modURL" :disabled="!scraperCompleted" type="text" />
      </b-field>
      <b-button :disabled="!scraperCompleted" @click="uploadMod(form)">Upload Everywhere</b-button>

      <div class="divider" />
      <div class="columns">
        <div class="column">
          <b-button :disabled="!scraperCompleted" @click="uploadMod(form)">Upload Everywhere</b-button>
        </div>
        <div class="column">
          <b-button :disabled="!scraperCompleted" @click="uploadMod(form)">Upload to TCAdmin</b-button>
        </div>
        <div class="column">
          <b-button :disabled="!scraperCompleted" @click="uploadMod(form)">Upload to LOW.MS Panel</b-button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'Scraper',
  components: {},
  props: ['scrapers'],
  data() {
    return {
      editProps: {},
      selectedScraperGame: '',
      selectedScraper: '',
      scrapeURL: '',
      scraperCompleted: false,
      scrapedMod: {}
    };
  },
  computed: {},
  created() {},
  methods: {
    ...mapActions({
      scrapeMod: 'mod/scrapeMod'
    }),
    async runScraper() {
      this.scrapedMod = await this.scrapeMod({ game: this.selectedScraperGame, scraper: this.selectedScraper, URL: this.scrapeURL });
      this.scrapedMod.modHomepageURL = this.scrapeURL;
      this.scraperCompleted = true;
    }
  }
};
</script>
