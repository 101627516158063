import Website from './Website';

export const routes = [
  {
    path: '/websites',
    name: 'Website',
    component: Website,
    meta: {
      title: 'Website'
    }
  }
];

export default routes;
