<template>
  <section>
    <div class="columns">
      <div class="column">
        <b-field label="Game">
          <b-select placeholder="Select a game" v-model="selectedAPIGame">
            <option v-for="item in this.apis" :value="item.game" v-bind:key="item.game">
              {{ item.game }}
            </option>
          </b-select>
        </b-field>
      </div>

      <div class="column">
        <b-field label="API">
          <b-select :disabled="selectedAPIGame.length === 0" v-model="selectedAPI">
            <option v-for="item in this.apis" :value="item.name" v-bind:key="item.name">
              {{ item.name }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <b-field label="URL">
      <b-input v-model="pluginPage" placeholder="Enter a URL" type="text" />
    </b-field>
    <b-button :disabled="pluginPage.length === 0" @click="runAPI()">Start API</b-button>
    <div class="divider" />
    <div>
      <b-field label="Mod Name">
        <b-input v-model="APIMod.name" :disabled="!APICompleted" type="text" />
      </b-field>
      <b-field label="Group Name">
        <b-input v-model="APIMod.modGroup" :disabled="!APICompleted" type="text" />
      </b-field>
      <b-field label="Extract Path">
        <b-input v-model="APIMod.modExtractPath" :disabled="!APICompleted" type="text" />
      </b-field>
      <b-field label="Comments">
        <b-input type="textarea" v-model="APIMod.comments" :disabled="!APICompleted" />
      </b-field>
      <b-field label="Help URL">
        <b-input v-model="APIMod.help_url" :disabled="!APICompleted" type="text" />
      </b-field>
      <b-field label="Homepage URL">
        <b-input v-model="APIMod.package_url" :disabled="!APICompleted" type="text" />
      </b-field>
      <b-field label="Download URL">
        <b-input v-model="APIMod.downloadLink" :disabled="!APICompleted" type="text" />
      </b-field>

      <div class="divider" />
      <div class="columns" v-if="!isEdit">
        <div class="column">
          <b-button disabled="true" @click="uploadMod">Upload Everywhere</b-button>
        </div>
        <div class="column result">
          <b-button
            :disabled="!APICompleted"
            :class="{ 'is-success': APICompleted, 'is-primary': !APICompleted }"
            @click.prevent="() => uploadMod(APIMod)"
            >Upload to TCAdmin</b-button
          >
        </div>
        <div class="column">
          <b-button disabled="true" @click="uploadMod">Upload to LOW.MS Panel</b-button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'API',
  components: {},
  props: ['apis', 'editMod'],
  data() {
    return {
      mod: {},
      selectedAPIGame: '',
      selectedAPI: '',
      pluginPage: '',
      APICompleted: false,
      APIMod: {},
      isEdit: false
    };
  },
  computed: {},
  created() {
    if (this.editMod) {
      this.APIMod = this.editMod;
      this.isEdit = true;
    }
  },
  methods: {
    ...mapActions({
      callAPI: 'mod/callAPI',
      uploadModToTCAdmin: 'mod/uploadMod'
    }),
    async runAPI() {
      this.APICompleted = false;
      this.APIMod = await this.callAPI({ game: this.selectedAPIGame, api: this.selectedAPI, URL: this.pluginPage });
      this.APICompleted = true;
    },
    async resetForm() {
      this.APIMod = {};
      this.APICompleted = false;
    },
    async uploadMod(APIMod) {
      this.mod = {
        game: this.selectedAPIGame,
        APIMod
      };
      const response = await this.uploadModToTCAdmin(this.mod);
      if (response === 'Uploaded') {
        this.$buefy.toast.open({
          duration: 10000,
          container: 'result',
          message: 'Mod uploaded',
          position: 'is-bottom',
          type: 'is-success'
        });
        this.resetForm();
      } else {
        this.$buefy.toast.open({
          duration: 10000,
          container: 'result',
          message: 'Error uploading mod',
          position: 'is-bottom',
          type: 'is-danger'
        });
      }
    }
  }
};
</script>
